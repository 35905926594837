import { getPointsSystem, getNextMatches, getAllMatches, saveMatches, getPrevMatches } from  "../../services/results.service";
import { Dispatch } from "redux";
import { 
    EDIT_NEXT_MATCHES,
    EDIT_MATCHES_POINTS,
    EDIT_PREV_MATCHES,
    LOADINGLIVE,
    CLEAR_PRED,
    EDIT_TOP_MATCHES,
    TOP_MATCHES_TEMP,
} from "../actionTypes/results.actionTypes";
import { LOADING } from "../actionTypes/ui.actionTypes";

export const getPointSystemAction = () => (dispatch: Dispatch) => {
    return getPointsSystem().then( (res) => {
        if (res && res.status === 200) {
            const points = res.data.system.matchScores;
            // console.log(res.data.system.matchScores);
            dispatch({
                type: EDIT_MATCHES_POINTS,
                payload: {
                    points
                }
            });
        }   
    }
    );
};

export const saveMatchAction = (data: any) => (dispatch: Dispatch) => {
    return saveMatches(data).then((res: any) => {
        if (res && res.status === 200) {
            getNextMatchesAction();
        }   
    }
    );
}; //EDIT_MATCHES_POINTS


export const clearpred = () => (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_PRED
    });
}; 

export const getTopMatchesAction = (data?: any) => (dispatch: Dispatch) => {
    if (data?.load) {
        dispatch({
            type: LOADINGLIVE,
            payload: true
        });
    }
    return getAllMatches().then( (res) => {
        if (res && res.status === 200) {
            const topMatches = res.data && res.data.fixtures ?  res.data.fixtures : [];
            if(data?.socket){
                dispatch({
                    type: TOP_MATCHES_TEMP,
                    payload: {
                       topMatches
                    }
                });
            }else{
                dispatch({
                    type: EDIT_TOP_MATCHES,
                    payload: {
                        topMatches
                    }
                });
            }
        } else {
            if (data && data.onError) {
                data.onError();
            }             
        }   
    }
    )
    .catch(_err => {
        dispatch({
            type: LOADINGLIVE,
            payload: false
        })
    });
}; //EDIT_TOP_MATCHES


export const getNextMatchesAction = (data?: any) => (dispatch: Dispatch) => {
    dispatch({
        type: LOADINGLIVE,
        payload: true
    })
    return getNextMatches().then( (res) => {
        if (res && res.status === 200) {
            const nextMatches = res.data && res.data.fixtures ?  res.data.fixtures : [];
            if (data && data.onSuccess) {
                data.onSuccess();
            } 
            dispatch({
                type: EDIT_NEXT_MATCHES,
                payload: {
                    nextMatches
                }
            });
        } else {
            if (data && data.onError) {
                data.onError();
            }             
        }   
    }
    )
    .catch(_err => {
        dispatch({
            type: LOADINGLIVE,
            payload: false
        })
        if (data && data.onError) {
            data.onError();
        }   
    });
}; //EDIT_MATCHES_POINTS

export const getPrevMatchesAction = (data?: any) => (dispatch: Dispatch) => {
    return getPrevMatches().then( (res) => {
        if (res && res.status === 200) {
            const prevMatches = res.data && res.data.fixtures ?  res.data.fixtures : [];
            if (data && data.onSuccess) {
                data.onSuccess();
            }
            dispatch({
                type: EDIT_PREV_MATCHES,
                payload: {
                    prevMatches
                }
            });
        } else {
            if (data && data.onError) {
                data.onError();
            }             
        }     
    }
    )
    .catch(_err => {
        if (data && data.onError) {
            data.onError();
        }   
    });
}; //EDIT_MATCHES_POINTS