import { combineReducers } from "redux";
import StateReducer from "./stateReducer";
import authReducer from "./authReducer";
import uiReducer from "./uiReducer";
import predictionReducer from "./predictions.reducer";
import resultsReducer from "./results.reducer";
import Prediction from './prediction.jsx';

const appReducer = combineReducers({
  prediction: Prediction,
  predictionManager: predictionReducer,
  stateManager: StateReducer,
  authManager: authReducer,
  uiManager: uiReducer,
  resultsReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    state = undefined;
    localStorage.clear();
  }
  return appReducer(state, action);
};

export default rootReducer;
