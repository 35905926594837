import React from "react";

const QuestionIcon = ({ active, color, onAction = null }) => {
  return (
    <svg
      onClick={() => onAction && onAction()}
      xmlns="http://www.w3.org/2000/svg"
      width="35.267"
      height="35.267"
      viewBox="0 0 35.267 35.267"
    >
      <g id="about" transform="translate(0)">
        <path
          id="Path_3959"
          data-name="Path 3959"
          d="M17.634,35.267A17.634,17.634,0,0,1,5.165,5.165,17.634,17.634,0,0,1,30.1,30.1a17.518,17.518,0,0,1-12.469,5.165Zm0-33.063a15.43,15.43,0,1,0,15.43,15.43A15.447,15.447,0,0,0,17.634,2.2Z"
          transform="translate(0 0)"
          fill={color ?? "#2b2b2b"}
        />
        <path
          id="Path_3960"
          data-name="Path 3960"
          d="M181.511,114.736a1.1,1.1,0,0,1-1.1-1.1,10.346,10.346,0,0,1,3.05-7.364,6.476,6.476,0,0,0,1.909-4.609,3.46,3.46,0,0,0-3.456-3.456h-.251a3.46,3.46,0,0,0-3.456,3.456v.4a1.1,1.1,0,0,1-2.2,0v-.4A5.667,5.667,0,0,1,181.66,96h.251a5.667,5.667,0,0,1,5.661,5.66,8.665,8.665,0,0,1-2.555,6.168,8.156,8.156,0,0,0-2.4,5.806A1.1,1.1,0,0,1,181.511,114.736Z"
          transform="translate(-163.877 -89.387)"
          fill={color ?? "#2b2b2b"}
        />
        <path
          id="Path_3961"
          data-name="Path 3961"
          d="M241.117,402.2a1.1,1.1,0,0,1,0-2.2h0a1.1,1.1,0,1,1,0,2.2Z"
          transform="translate(-223.482 -372.447)"
          fill={color ?? "#2b2b2b"}
        />
      </g>
    </svg>
  );
};

export default QuestionIcon;
