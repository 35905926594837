import React from 'react';
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBarMenu from "../SideBarMenu/SideBarMenu";
import Footer from "../Footer/Footer";
import LoadingOverlay from "react-loading-overlay";

import "./AppLayout.css";
import { useEffect } from "react";
import { resetLoadingAction } from "../../store/actions/authActions";

const PublicRoute = ({ children, ...rest }) => {
  const message = useSelector((state) => state.uiManager.message);
  const isLoading = useSelector((state) => state.uiManager.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetLoadingAction());
  }, []);
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <>
          <LoadingOverlay active={isLoading} spinner text={message}>
            {children}
          </LoadingOverlay>
        </>
      )}
    />
  );
};
export default PublicRoute;
