import React from 'react';
import './DrawerCharts.css';
import ApexChart from 'react-apexcharts';

const DrawerCharts = ({ scores, match, type, color }) => {

    const counts = scores?.map(score => score.count) || [];
    const data = {
        series: [{
            name: "People",
            data: counts, 
        }],
        options: {
            chart: {
              type: 'bar',
              height: 120,
              toolbar: {
                  show: false,
              }
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
                columnWidth: '75%',
              }
            },
            dataLabels: {
              enabled: false
            },
            grid: {
                show: false,
            },
            colors: [color],
            xaxis: {
              categories: scores?.map(score => score.score) || []
            },
            yaxis: {
              labels: {
                formatter: function(val) {
                  return Math.floor(val)
                }
              },
                // min: 0,
                // max: 10,
            }
          },
          tooltip: {
            y: {
              title: "People",
            },
          }
    }

    return (
        <>
        <div className={`drawer__match_title`} >{type === "draw" ? "Draw" : type === "home" ? match.homeTeam + ' Wins' : match.awayTeam + ' Wins'}</div>
        {
            counts?.length > 0 &&  <div className={`drawer__charts ${scores?.length < 3 ? "one" : scores?.length <= 6 ? "two" : "three"}`} ><ApexChart options={data.options} series={data.series} type="bar" height={350} /></div> 
        }
        {
            counts?.length === 0 &&  <div className="drawer__charts_empty" >-</div> 
        }
        </>
    );
}

export default DrawerCharts;