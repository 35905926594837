/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import Logout from "./components/Auth/Logout";
import PrivateRoute from "./components/Auth/PrivateRoute";
import PublicRoute from "./components/Auth/PublicRoute";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "./hooks";
import { CircularProgress } from '@material-ui/core';
import { logoutAction } from './store/actions/authActions';
import { getPointSystemAction } from "./store/actions/results.Actions";
import { API_URL } from "./constants/http";
import { AppAxios } from './services/authService';
import ResultsDrawer from './components/Results/ResultsDrawer';
import "./index.css";

// Lazy load components
const WelcomePage = lazy(() => import('./pages/welcome/index'));
const PredictionsPage = lazy(() => import("./pages/Predictions/Predi.jsx"));
const Resultspage = lazy(() => import('./pages/Results/Results'));
const Leaderboardpage = lazy(() => import('./pages/Leaderboard/Leaderboard.jsx'));
const Leaderboardinsidepage = lazy(() => import('./pages/Leaderboard/Leaderboardinside'));
const Pointsystempage = lazy(() => import('./pages/Pointsystem/Pointsystem'));

const Resetpassword = lazy(() => import('./pages/resetpassword/HomePage'));
const Confirmpassword = lazy(() => import('./pages/confirmpassword/HomePage'));
const Profile = lazy(() => import('./pages/profile/contactus'));
const PP = lazy(() => import('./pages/PP'));
const DD = lazy(() => import('./pages/DD'));
const Verify = lazy(() => import('./pages/verify'));
const Redirect = lazy(() => import('./pages/verify/redirect'));
const AdminAllUsers = lazy(() => import('./pages/admin/users'));
const AdminAllLeagues = lazy(() => import('./pages/admin/leagues'));
const AdminFixtures = lazy(() => import('./pages/admin/fixtures'));
const AdminFixturesStatuses = lazy(() => import('./pages/admin/FixturesStatuses'));
const Contactus = lazy(() => import('./pages/contactus/contactus'));
const AppRegister = lazy(() => import('./pages/welcome/register'));

const AppRouter = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerMatch, setDrawerMatch] = useState({});
  const topContainer = useRef();

  const history = useHistory();

  const [validAuthToken, setValidAuthToken] = useState(false);
  const [validatingAuthToken, setValidatingAuthToken] = useState(true);

  const onOpenDrawer = (isOpen, match) => {
    setDrawerOpen(isOpen);
    setDrawerMatch(match);
  }

  const dispatch = useDispatch();
  const nextMatches = useAppSelector(
    (state) => state.resultsReducer.nextMatches
  );

  const logOut = () => {
    localStorage.clear();
    localStorage.removeItem('token');
    window.localStorage.clear();
    window.FB && window.FB.logout();
    dispatch(logoutAction());
    window.location.reload();
  }

  const validateAuthToken = async () => {
    setValidatingAuthToken(true);
    const authToken = localStorage.getItem("token");
    if (authToken) {
      try {
        await AppAxios.post(`${API_URL}/api/auth/check_auth`);
        setValidAuthToken(true);
        setValidatingAuthToken(false);
      } catch (e) {
        setValidAuthToken(false);
        setValidatingAuthToken(false);
        logOut();
      }
    } else {
      setValidatingAuthToken(false);
    }
  }

  useEffect(() => {
    validateAuthToken();
  }, []);

  useEffect(() => {
    if (validAuthToken) {
      dispatch(getPointSystemAction());
    }
  }, [validAuthToken]);

  return (
    <>
      {validatingAuthToken ? (
        <div className='validating-auth-wrapper'>
          <CircularProgress size={48} thickness={8} />
        </div>
      ) : (
        <div className="top-ofall" ref={topContainer}>
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <PrivateRoute path="/predictions">
                  <PredictionsPage />
                </PrivateRoute>
                <PrivateRoute path="/profile">
                  <Profile />
                </PrivateRoute>
                <PrivateRoute path="/results">
                  <Resultspage nextmatches={nextMatches} onOpenDrawer={onOpenDrawer} />
                </PrivateRoute>
                <PrivateRoute exact path="/leaderboard">
                  <Leaderboardpage key={Math.random()} nextmatches={nextMatches} onOpenDrawer={onOpenDrawer} />
                </PrivateRoute>
                <PrivateRoute exact path="/pointsystem">
                  <Pointsystempage nextmatches={nextMatches} onOpenDrawer={onOpenDrawer} />
                </PrivateRoute>
                <PublicRoute exact path="/contact_us">
                  <Contactus />
                </PublicRoute>
                <PrivateRoute exact path="/contactus">
                  <Contactus />
                </PrivateRoute>
                <PrivateRoute exact path="/leaderboard/:id">
                  <Leaderboardinsidepage nextmatches={nextMatches} onOpenDrawer={onOpenDrawer} />
                </PrivateRoute>
                <PrivateRoute exact path="/admin/allleagues">
                  <AdminAllLeagues />
                </PrivateRoute>
                <PrivateRoute exact path="/admin/allusers">
                  <AdminAllUsers />
                </PrivateRoute>
                <PrivateRoute exact path="/admin/fixtures">
                  <AdminFixtures />
                </PrivateRoute>
                <PrivateRoute exact path="/admin/statuses">
                  <AdminFixturesStatuses />
                </PrivateRoute>
                <PrivateRoute path="/logout">
                  <Logout />
                </PrivateRoute>
                <PublicRoute exact path="/">
                  <WelcomePage />
                </PublicRoute>
                <PublicRoute exact path="/signup">
                  <AppRegister />
                </PublicRoute>
                <PublicRoute exact path="/reset-password">
                  <Resetpassword />
                </PublicRoute>
                <PublicRoute exact path="/reset-password/resend">
                  <Resetpassword retries={true} />
                </PublicRoute>
                <PublicRoute exact path="/confirm-password">
                  <Confirmpassword />
                </PublicRoute>
                <PublicRoute exact path="/privacy-policy">
                  <PP />
                </PublicRoute>
                <PublicRoute exact path="/data-policy">
                  <DD />
                </PublicRoute>
                <PublicRoute exact path="/verify/:response">
                  <Verify />
                </PublicRoute>
                <PublicRoute path="/api/auth/verify/*">
                  <Redirect />
                </PublicRoute>
              </Switch>
            </Suspense>
          </Router>
          {drawerOpen && <ResultsDrawer isOpen={drawerOpen} match={drawerMatch} handleClose={() => setDrawerOpen(false)} />}
        </div>
      )}
    </>
  );
};

export default AppRouter;
