import axios from "axios";
import { API_URL, APPLICATION_JSON } from "../constants/http";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;

export const AppAxios = axios;

export const register = async (data: IsignUp) => {
  const result = await axios
    .post(
      `${API_URL}/api/auth/register`,
      JSON.stringify(data),
      APPLICATION_JSON
    )
    .then((data) => data);
  return result;
};

export const login = async (data: ILogin) => {
  const result = await axios
    .post(`${API_URL}/api/auth/login`, JSON.stringify(data), APPLICATION_JSON)
    .then((data) => data);
  return result;
};

export const reset = async (data: IForgot) => {
  const result = await axios
    .post(`${API_URL}/api/auth/reset-password`, JSON.stringify(data), APPLICATION_JSON)
    .then((data) => data);
  return result;
};

export const resetpass = async (data: IConfirm) => {
  const result = await axios
    .post(`${API_URL}/api/auth/new-password`, JSON.stringify(data), APPLICATION_JSON)
    .then((data) => data);
  return result;
};

export const verify = async (data: IVerify) => {
  const result = await axios
    .post(`${API_URL}/api/auth/verify-email`, JSON.stringify(data), APPLICATION_JSON)
    .then((data) => data);
  return result;
};

export const socialLogin = async (data: ISocialLogin) => {
  const result = await axios
    .post(
      `${API_URL}/api/auth/verify-token`,
      JSON.stringify(data),
      APPLICATION_JSON
    )
    .then((data) => data);
  return result;
};
