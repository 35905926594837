import React from 'react';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../store/actions/authActions";
declare global {
  interface Window {
    FB: any;
  }
}

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    localStorage.removeItem('token');
    window.localStorage.clear();
    window.FB && window.FB.logout();
    dispatch(logoutAction());
    window.location.reload();
  }, []);
  return <p>Logging out...</p>;
};

export default Logout;
