import React from "react";

const ContactIcon = ({ active }) => {
  return (
    <svg
      id="_066---Contact"
      data-name="066---Contact"
      xmlns="http://www.w3.org/2000/svg"
      width="36.565"
      height="36.566"
      viewBox="0 0 36.565 36.566"
    >
      <path
        id="Shape"
        d="M35.127,6.094H13.493l-1.4-4.637A2.062,2.062,0,0,0,9.536.086L6.433,1a2.072,2.072,0,0,0-.9.545l-3.5,3.622a2.053,2.053,0,0,0-.524.989,31.642,31.642,0,0,0,.993,15.327,31.969,31.969,0,0,0,7.673,13.355,2.065,2.065,0,0,0,.983.539l4.932,1.138a2.065,2.065,0,0,0,.465.053,2.091,2.091,0,0,0,.587-.085l1.717-.506,1.384-.408a2.036,2.036,0,0,0,1.23-.993,2.015,2.015,0,0,0,.158-1.551l-.772-2.553H35.127a2.438,2.438,0,0,0,2.438-2.438V8.532a2.438,2.438,0,0,0-2.438-2.438ZM25.529,18.1,36.271,8.126a1.2,1.2,0,0,1,.075.406v19.5a1.212,1.212,0,0,1-.068.383Zm9.6-10.787a1.21,1.21,0,0,1,.206.021L22.573,19.177a1.219,1.219,0,0,1-1.658,0L12.14,11.053l.647-.191a2.042,2.042,0,0,0,1.228-.993,2.011,2.011,0,0,0,.157-1.55l-.307-1.007ZM10.588,25.127A14.831,14.831,0,0,1,7.837,19.9a13.74,13.74,0,0,1-.31-7.445.109.109,0,0,1,.075-.067l3.173-.935,7.157,6.623ZM20.4,33.994a.827.827,0,0,1-.5.4l-.808.238-1.484-4.911a.609.609,0,1,0-1.166.353l1.485,4.9-1.125.332a.853.853,0,0,1-.435.013l-4.932-1.137a.853.853,0,0,1-.4-.223A30.69,30.69,0,0,1,3.671,21.13,30.41,30.41,0,0,1,2.7,6.412a.837.837,0,0,1,.213-.4l3.493-3.62a.847.847,0,0,1,.366-.223l1.134-.334,1.484,4.91a.609.609,0,0,0,.583.433.585.585,0,0,0,.176-.026.609.609,0,0,0,.407-.759L9.08,1.49l.8-.236a.843.843,0,0,1,1.048.555L13,8.672a.8.8,0,0,1-.061.618.832.832,0,0,1-.5.4l-.809.238-.239-.792a.609.609,0,0,0-1.166.352l.237.785-3.2.945a1.329,1.329,0,0,0-.906.914,14.978,14.978,0,0,0,.32,8.116,15.124,15.124,0,0,0,4.234,6.959,1.324,1.324,0,0,0,1.258.274l3.212-.947.24.792a.609.609,0,1,0,1.166-.353l-.237-.785.8-.236a.845.845,0,0,1,1.048.555l2.072,6.863a.8.8,0,0,1-.063.622Zm.093-4.742-.934-3.09A2.063,2.063,0,0,0,17,24.79l-5.18,1.527a.122.122,0,0,1-.108-.02c-.085-.075-.183-.168-.3-.274l7.413-7.118,1.258,1.165a2.446,2.446,0,0,0,3.316,0l1.231-1.143,10.73,10.3a1.211,1.211,0,0,1-.237.026Z"
        transform="translate(-0.999 -0.001)"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M26.609,15.219H36.36a.609.609,0,1,0,0-1.219H26.609a.609.609,0,1,0,0,1.219Z"
        transform="translate(-10.764 -5.469)"
      />
      <path
        id="Shape-3"
        data-name="Shape"
        d="M55.609,22a.609.609,0,0,0-.609.609v8.532a.609.609,0,1,0,1.219,0V22.609A.609.609,0,0,0,55.609,22Z"
        transform="translate(-22.091 -8.593)"
      />
    </svg>
  );
};

export default ContactIcon;
