import React, { useRef } from 'react';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import ContactIcon from "../../assets/icons/ContactIcon";
import CupIcon from "../../assets/icons/CupIcon";
import GroupIcon from "../../assets/icons/GroupIcon";
import NewsIcon from "../../assets/icons/NewsIcon";
import PointSystemIcon from "../../assets/icons/PointSystemIcon";
import QuestionIcon from "../../assets/icons/QuestionIcon";
import RankingsIcon from "../../assets/icons/RankingsICon";
import ResultsIcon from "../../assets/icons/ResultsIcon";
import Usericon from '../../assets/icons/usericon';
import logo from "../../assets/images/logo.png";
import { useSelector } from 'react-redux';
import "./SideBarMenu.css";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';


const drawerWidth = '20vh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      // position: "static"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      // transition: theme.transitions.create(['margin', 'width'], {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
  }),
);

export default function PermanentDrawerLeft(props: any) {
  const auth: any = useSelector(state => state);
  const user = auth?.authManager?.user;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const pathname = history.location.pathname;
  const myRef = useRef(null)
  const onClickParent = props.onClick;
  const onClick = (goToPage: any) => {

    history.push(goToPage)

    document.getElementById("root")?.scrollTo(0, 0);
    //window.scrollTo(0,0);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor='left'
        open={open}
      >
        <div className={`sidebarMenu__item`}>
          <a onClick={() => onClick("/predictions")} >

            <img src={logo} alt="logo" />
            <div className="logo-text"><b>AmigosCup</b></div>
          </a>
        </div>

        <div className={`sidebarMenu__item ${(["/leaderboard", "/admin/allleagues"].includes(pathname)) && "sidebarMenu__item_active"}`}>
          <a onClick={() => onClick(user?.role === "admin" ? "/admin/allleagues" : "/leaderboard")} >
            <CupIcon active={["/leaderboard", "/admin/allleagues"].includes(location.pathname)} />
            <h3>Leaderboard</h3>
          </a>
        </div>

        {
          user?.role === "admin"
          &&
          <div className={`sidebarMenu__item ${(["/admin/allusers"].includes(pathname)) && "sidebarMenu__item_active"}`}>
            <a onClick={() => onClick("/admin/allusers")} >
              <GroupIcon active={["/admin/allusers"].includes(location.pathname)} />
              <h3>All users</h3>
            </a>
          </div>
        }

        {
          user?.role === "admin"
          &&
          <div className={`sidebarMenu__item ${(["/admin/fixtures"].includes(pathname)) && "sidebarMenu__item_active"}`}>
            <a onClick={() => onClick("/admin/fixtures")} >
              <CupIcon active={["/admin/fixtures"].includes(location.pathname)} />
              <h3>Teams</h3>
            </a>
          </div>
        }

        {
          (user && user.role === "admin") &&
          <div className={`sidebarMenu__item ${(["/admin/statuses"].includes(pathname)) && "sidebarMenu__item_active"}`}>
            <a onClick={() => onClick("/admin/statuses")} >
              <CupIcon active={["/admin/statuses"].includes(location.pathname)} />
              <h3>Live Scores</h3>
            </a>
          </div>
        }

        <div className={`sidebarMenu__item ${(pathname === "/predictions") && "sidebarMenu__item_active"}`}>
          <a onClick={() => onClick("/predictions")} >
            <RankingsIcon active={location.pathname === "/predictions"} />
            <h3>Predictions</h3>
          </a>
        </div>

        <div className={`sidebarMenu__item ${(pathname === "/results") && "sidebarMenu__item_active"}`}>
          <a onClick={() => onClick("/results")} >
            <PointSystemIcon active={location.pathname === "/pointsystem"} />
            {/* <ResultsIcon active={location.pathname === "/results"} /> */}
            <h3>Scores Guesses</h3>
          </a>
        </div>

        <div className={`sidebarMenu__item ${(pathname === "/pointsystem") && "sidebarMenu__item_active"}`}>
          <a onClick={() => onClick("/pointsystem")} >
            {/* <PointSystemIcon active={location.pathname === "/pointsystem"} /> */}
            <ResultsIcon active={location.pathname === "/results"} />
            <h3>Points System</h3>
          </a>
        </div>

        {/* <div className="sidebarMenu__item">
         <a href="https://www.facebook.com/FootballAroundMe/">
         <QuestionIcon
            onAction={() => window.open("https://www.facebook.com/FootballAroundMe/")}
            active={location.pathname === "/results"}
            color="#2b2b2b"
          />
          <h3>About</h3>
        </a>
      </div> */}

        <div className={`sidebarMenu__item ${(pathname === "/contactus") && "sidebarMenu__item_active"}`}>
          <a onClick={() => onClick("/contactus")} >
            <ContactIcon active={location.pathname === "/contactus"} />
            <h3>Contact</h3>
          </a>
        </div>

        <div className={`sidebarMenu__item ${(pathname === "/profile") && "sidebarMenu__item_active"}`}>
          <a onClick={() => onClick("profile")}>
            <Usericon />
            <h3>Profile</h3>
          </a>
        </div>

        <div className={`sidebarMenu__item ${(pathname === "/logout") && "sidebarMenu__item_active"}`}>
          <a onClick={() => onClick("/logout")}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <h3>Logout</h3>
          </a>
        </div>
      </Drawer>
    </div>
  );
}

