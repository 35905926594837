import { object } from "yup/lib/locale";
import { thirdPlaceMap } from "../data/thirdPlaceAlgo";
import { round16 } from "../fakeAPI/predictionRounds";
import { useAppSelector } from "../hooks";
import { store } from "../store";
import { IGroupTeam } from "../types/api";
import { IMatchWinner, INextRound } from "../types/match";
import { IAddPosition } from "../types/predictions";
import { ITeam } from "../types/team";

export const round16Matcher = (data: IAddPosition) => {
  // grab current state
  const state = store.getState();
  const teams = state.predictionManager.teams;
  const round16 = state.predictionManager.round16;
  let group = data.group;
  let position = parseInt(Object.keys(data.position)[0]);
  let teamId = Object.values(data.position)[0];
  let teamObj = teams.filter((team: IGroupTeam) => team.id === teamId)[0];

  console.log(group, position, teamId, teamObj);

  /*
   Match 1
   Winner Group B	 VS 3rd Group A/D/E/F
  */
  if (group === "B" && position === 1) {
    return {
      matchId: 1,
      homeTeam: teamObj.name,
      homeTeamFlag: teamObj.flag,
      homeTeamGroupPosition: `${position}${group}`,
      homeTeamId: teamObj.id,
    };
  }

  /*
   Match 2
   Winner Group A	 VS  Runner-up Group C
  */
  if (group === "A" && position === 1) {
    return {
      matchId: 2,
      homeTeam: teamObj.name,
      homeTeamFlag: teamObj.flag,
      homeTeamGroupPosition: `${position}${group}`,
      homeTeamId: teamObj.id,
    };
  }

  if (group === "C" && position === 2) {
    return {
      matchId: 2,
      awayTeam: teamObj.name,
      awayTeamFlag: teamObj.flag,
      awayTeamGroupPosition: `${position}${group}`,
      awayTeamId: teamObj.id,
    };
  }

  /*
   Match 3
   Winner Group F  VS  3rd Group A/B/C
  */
  if (group === "F" && position === 1) {
    return {
      matchId: 3,
      homeTeam: teamObj.name,
      homeTeamFlag: teamObj.flag,
      homeTeamGroupPosition: `${position}${group}`,
      homeTeamId: teamObj.id,
    };
  }

  /*
   Match 4
   Runner-up Group D  VS  Runner-up Group E
  */
  if (group === "D" && position === 2) {
    return {
      matchId: 4,
      homeTeam: teamObj.name,
      homeTeamFlag: teamObj.flag,
      homeTeamGroupPosition: `${position}${group}`,
      homeTeamId: teamObj.id,
    };
  }

  if (group === "E" && position === 2) {
    return {
      matchId: 4,
      awayTeam: teamObj.name,
      awayTeamFlag: teamObj.flag,
      awayTeamGroupPosition: `${position}${group}`,
      awayTeamId: teamObj.id,
    };
  }

  /*
   Match 5
   Winner Group E  VS  3rd Group A/B/C/D
  */
  if (group === "E" && position === 1) {
    return {
      matchId: 5,
      homeTeam: teamObj.name,
      homeTeamFlag: teamObj.flag,
      homeTeamGroupPosition: `${position}${group}`,
      homeTeamId: teamObj.id,
    };
  }

  /*
   Match 6
   Winner Group D	 VS  Runner-up Group F
  */
  if (group === "D" && position === 1) {
    return {
      matchId: 6,
      homeTeam: teamObj.name,
      homeTeamFlag: teamObj.flag,
      homeTeamGroupPosition: `${position}${group}`,
      homeTeamId: teamObj.id,
    };
  }

  if (group === "F" && position === 2) {
    return {
      matchId: 6,
      awayTeam: teamObj.name,
      awayTeamFlag: teamObj.flag,
      awayTeamGroupPosition: `${position}${group}`,
      awayTeamId: teamObj.id,
    };
  }

  /*
   Match 7
   Winner Group C	VS  3rd Group D/E/F
  */
  if (group === "C" && position === 1) {
    return {
      matchId: 7,
      homeTeam: teamObj.name,
      homeTeamFlag: teamObj.flag,
      homeTeamGroupPosition: `${position}${group}`,
      homeTeamId: teamObj.id,
    };
  }

  /*
   Match 8
   Runner-up Group A	VS  Runner-up Group B
  */
  if (group === "A" && position === 2) {
    return {
      matchId: 8,
      homeTeam: teamObj.name,
      homeTeamFlag: teamObj.flag,
      homeTeamGroupPosition: `${position}${group}`,
      homeTeamId: teamObj.id,
    };
  }

  if (group === "B" && position === 2) {
    return {
      matchId: 8,
      awayTeam: teamObj.name,
      awayTeamFlag: teamObj.flag,
      awayTeamGroupPosition: `${position}${group}`,
      awayTeamId: teamObj.id,
    };
  }

  return null;
};

export const round16BestLoserMatcher = (data: IGroupTeam[]) => {
  let groupArr = data.map((bestLoser) => bestLoser.group).sort();
  let groupComparison: string = groupArr.join("");
  console.log("groupComparison =", groupComparison);
  let matches: any = thirdPlaceMap[groupComparison];
  let finalM: any;
  Object.keys(matches).map((key) => {
    let matchedTeam = data.filter((team) => team.group === matches[key])[0];

    finalM = {
      ...finalM,
      [key]: {
        awayTeam: matchedTeam.name,
        awayTeamFlag: matchedTeam.flag,
        awayTeamGroupPosition: `3${matches[key]}`,
        awayTeamId: matchedTeam.id,
      },
    };
  });

  return finalM;
};

export const WinnerNextRoundMatcher = (data: IMatchWinner) => {
  // grab current state
  const state = store.getState();
  const teams = state.predictionManager.teams;
  let winner = teams.filter((team: IGroupTeam) => team.id === data.winner)[0];

  if (data.round === "round16") {
    // Quarter FInals Match 1
    if (data.matchId === 1) {
      let nextRoundData: INextRound = {
        nextRound: "quarterFinals",
        matchId: 1,
        team: {
          homeTeam: winner.name,
          homeTeamFlag: winner.flag,
          homeTeamGroupPosition: data.groupPosition,
          homeTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    if (data.matchId === 2) {
      let nextRoundData: INextRound = {
        nextRound: "quarterFinals",
        matchId: 1,
        team: {
          awayTeam: winner.name,
          awayTeamFlag: winner.flag,
          awayTeamGroupPosition: data.groupPosition,
          awayTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    // Quarter FInals Match 2
    if (data.matchId === 3) {
      let nextRoundData: INextRound = {
        nextRound: "quarterFinals",
        matchId: 2,
        team: {
          homeTeam: winner.name,
          homeTeamFlag: winner.flag,
          homeTeamGroupPosition: data.groupPosition,
          homeTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    if (data.matchId === 4) {
      let nextRoundData: INextRound = {
        nextRound: "quarterFinals",
        matchId: 2,
        team: {
          awayTeam: winner.name,
          awayTeamFlag: winner.flag,
          awayTeamGroupPosition: data.groupPosition,
          awayTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    // Quarter FInals Match 3
    if (data.matchId === 5) {
      let nextRoundData: INextRound = {
        nextRound: "quarterFinals",
        matchId: 3,
        team: {
          homeTeam: winner.name,
          homeTeamFlag: winner.flag,
          homeTeamGroupPosition: data.groupPosition,
          homeTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    if (data.matchId === 6) {
      let nextRoundData: INextRound = {
        nextRound: "quarterFinals",
        matchId: 3,
        team: {
          awayTeam: winner.name,
          awayTeamFlag: winner.flag,
          awayTeamGroupPosition: data.groupPosition,
          awayTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    // Quarter FInals Match 4
    if (data.matchId === 7) {
      let nextRoundData: INextRound = {
        nextRound: "quarterFinals",
        matchId: 4,
        team: {
          homeTeam: winner.name,
          homeTeamFlag: winner.flag,
          homeTeamGroupPosition: data.groupPosition,
          homeTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    if (data.matchId === 8) {
      let nextRoundData: INextRound = {
        nextRound: "quarterFinals",
        matchId: 4,
        team: {
          awayTeam: winner.name,
          awayTeamFlag: winner.flag,
          awayTeamGroupPosition: data.groupPosition,
          awayTeamId: winner.id,
        },
      };
      return nextRoundData;
    }
  }

  // Semi Finals
  if (data.round === "quarterFinals") {
    // Semi FInals Match 1
    if (data.matchId === 1) {
      let nextRoundData: INextRound = {
        nextRound: "semiFinals",
        matchId: 1,
        team: {
          homeTeam: winner.name,
          homeTeamFlag: winner.flag,
          homeTeamGroupPosition: data.groupPosition,
          homeTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    if (data.matchId === 2) {
      let nextRoundData: INextRound = {
        nextRound: "semiFinals",
        matchId: 1,
        team: {
          awayTeam: winner.name,
          awayTeamFlag: winner.flag,
          awayTeamGroupPosition: data.groupPosition,
          awayTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    // Semi FInals Match 2
    if (data.matchId === 3) {
      let nextRoundData: INextRound = {
        nextRound: "semiFinals",
        matchId: 2,
        team: {
          homeTeam: winner.name,
          homeTeamFlag: winner.flag,
          homeTeamGroupPosition: data.groupPosition,
          homeTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    if (data.matchId === 4) {
      let nextRoundData: INextRound = {
        nextRound: "semiFinals",
        matchId: 2,
        team: {
          awayTeam: winner.name,
          awayTeamFlag: winner.flag,
          awayTeamGroupPosition: data.groupPosition,
          awayTeamId: winner.id,
        },
      };
      return nextRoundData;
    }
  }

  if (data.round === "semiFinals") {
    // Semi FInals Match 1
    if (data.matchId === 1) {
      let nextRoundData: INextRound = {
        nextRound: "final",
        matchId: 1,
        team: {
          homeTeam: winner.name,
          homeTeamFlag: winner.flag,
          homeTeamGroupPosition: data.groupPosition,
          homeTeamId: winner.id,
        },
      };
      return nextRoundData;
    }

    if (data.matchId === 2) {
      let nextRoundData: INextRound = {
        nextRound: "final",
        matchId: 1,
        team: {
          awayTeam: winner.name,
          awayTeamFlag: winner.flag,
          awayTeamGroupPosition: data.groupPosition,
          awayTeamId: winner.id,
        },
      };
      return nextRoundData;
    }
  }

  return null;
};
