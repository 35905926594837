import { object } from "yup/lib/locale";
import {
  finalsInitialData,
  quarterFinalsInitialData,
  round16InitialData,
  semiFinalsInitialData,
} from "../../data/euroRounds";
import { teams } from "../../fakeAPI/teams";
import { IpredictionReducer } from "../../types/predictions";
import { IHomeTeam, IMatchPrediction, INextRound } from "../../types/match";
import { round16BestLoserMatcher, round16Matcher } from "../../utils/Matcher";
import _ from "lodash";
import {
  ADD_GROUP_POSITION,
  REMOVE__GROUP_POSITION,
  RESET_POSITIONS,
  UPDATE_GROUP_POSITION,
  PROCESS_BEST_LOSERS,
  UPDADE_ROUND_16_MATCHES,
  ADD_GROUPS,
  UPDATE_BEST_LOSERS,
  CALCULATE_BEST_LOSERS_MATCHES,
  RESET_BEST_LOSERS,
  MATCH_NEXT_ROUND_GAME,
  TOGGLE_LOCK_SELECTION,
} from "../actionTypes/predictions";
import { IGroupTeam } from "../../types/api";

const initialState = {
  groups: [],
  standings: {},
  selectedPredictionsCount: 0,
  canShowBestLosersPick: false,
  thirdPositionTeams: [],
  round16: round16InitialData,
  quarterFinals: quarterFinalsInitialData,
  semiFinals: semiFinalsInitialData,
  final: finalsInitialData,
  teams: [],
  bestLosers: [],
  competition: "",
  isSelectionLocked: false,
  positions: [],
};

const stateReducer = (
  state: IpredictionReducer = initialState,
  { type, payload }: any
) => {
  switch (type) {
    case ADD_GROUPS:
      let defaultStandings: any = state.standings;

      if (_.isEmpty(state.standings)) {
        state.groups.map((group, index) => {
          defaultStandings[group.name] = [];
        });
      }
      return {
        ...state,
        groups: payload.groups,
        standings: defaultStandings,
        teams: payload.teams,
        competition: payload.competition,
      };
    case ADD_GROUP_POSITION:
      let groupPositions = state.standings[payload.group] ?? [];

      return {
        ...state,
        standings: {
          ...state.standings,
          [payload.group]: [...groupPositions, payload.position],
        },
        selectedPredictionsCount: state.selectedPredictionsCount + 1,
      };
    case REMOVE__GROUP_POSITION:
      return {
        ...state,
        standings: { ...state.standings, [payload.group]: payload.position },
        selectedPredictionsCount: state.selectedPredictionsCount - 1,
      };
    case TOGGLE_LOCK_SELECTION:
      return {
        ...state,
        isSelectionLocked: payload,
      };
    case UPDATE_GROUP_POSITION:
      return {
        ...state,
        standings: { ...state.standings, [payload.group]: payload.position },
      };
    case UPDATE_BEST_LOSERS:
      return {
        ...state,
        bestLosers: payload,
      };
    case RESET_POSITIONS:
      return {
        ...state,
        // standings: {},
        // selectedPredictionsCount: 0,
      };

    case MATCH_NEXT_ROUND_GAME:
      let nextRoundData: INextRound = payload;

      // Get Next Round
      let next = nextRoundData.nextRound as keyof typeof state;

      let nextRound = state[next];
      let finalMatch = { ...nextRound };
      if (next === "final") {
        let finalTeam = payload.team;
        Object.keys(finalTeam).map((key) => {
          finalMatch[key] = finalTeam[key];
        });
        return {
          ...state,
          [next]: finalMatch,
        };
      } else {
        let nextRoundMatchIndex = nextRound.findIndex(
          (fixture: any) => fixture.matchId === payload.matchId
        );
        let nextRoundUpdated: IMatchPrediction[] = [...nextRound];

        Object.keys(payload.team).map((key) => {
          nextRoundUpdated[nextRoundMatchIndex] = {
            ...nextRoundUpdated[nextRoundMatchIndex],
            [key]: payload.team[key],
          };
        });
        console.group(
          "MATCH_NEXT_ROUND_GAME REDUCER nextRoundUpdated=",
          nextRoundUpdated
        );

        return {
          ...state,
          [next]: nextRoundUpdated,
        };
      }

    case UPDADE_ROUND_16_MATCHES:
      let updatedTeam: IHomeTeam = payload;
      let matchIndex = state.round16.findIndex(
        (match) => match.matchId === updatedTeam.matchId
      );
      let round16Updated: IMatchPrediction[] = [...state.round16];
      Object.keys(payload).map((key) => {
        round16Updated[matchIndex] = {
          ...round16Updated[matchIndex],
          [key]: payload[key],
        };
      });

      return {
        ...state,
        round16: round16Updated,
      };
    case CALCULATE_BEST_LOSERS_MATCHES:
      console.log("CALCULATE_BEST_LOSERS_MATCHES");
      let bestLoserGames = round16BestLoserMatcher(state.bestLosers);
      let updatedround15Games = [...state.round16];
      Object.keys(bestLoserGames).map((matchNumber) => {
        let bestLoserMatches = bestLoserGames[matchNumber];
        let match = updatedround15Games.find(
          (game) => game.matchId === parseInt(matchNumber)
        );
        let matchIndex = updatedround15Games.findIndex(
          (match) => match.matchId === parseInt(matchNumber)
        );
        Object.keys(bestLoserMatches).map((key) => {
          updatedround15Games[matchIndex] = {
            ...updatedround15Games[matchIndex],
            [key]: bestLoserMatches[key],
          };
        });
      });

      return {
        ...state,
        round16: updatedround15Games,
        bestLoserGames: bestLoserGames,
      };
    case RESET_BEST_LOSERS:
      let loserGames = [1, 3, 5, 7];
      let updated16ROund = [...state.round16];
      loserGames.map((matchNumber) => {
        let matchIndex = updated16ROund.findIndex(
          (match) => match.matchId === matchNumber
        );

        updated16ROund[matchIndex] = {
          ...updated16ROund[matchIndex],
          awayTeam: "",
          awayTeamFlag: "",
          awayTeamGroupPosition: "",
        };
      });

      return {
        ...state,
        round16: updated16ROund,
      };
    case PROCESS_BEST_LOSERS:
      let standings = state.standings;
      let teamInfoArr = [];
      let thirdPlaceArr: string[] = [];
      Object.keys(standings).map((key) => {
        standings[key].map((positionObj) => {
          if (positionObj["3"]) {
            let pos = positionObj["3"];

            thirdPlaceArr.push(pos);
          }
        });
      });

      for (let teamId of thirdPlaceArr) {
        let teamInfo = state.teams.filter(
          (team: IGroupTeam) => team.id === teamId
        )[0];
        teamInfoArr.push(teamInfo);
      }

      return {
        ...state,
        canShowBestLosersPick: true,
        thirdPositionTeams: teamInfoArr,
      };

    default:
      return state;
  }
};

export default stateReducer;
