import { LOGIN_SUCCESS, LOGOUT, ERROR, SIGNUP_SUCCESS, CLEARSIGNUP, CLEARERROR, VERIFY_SUCCESS} from "../actionTypes/auth.actionTypes";
import * as types from '../actionTypes/predictions';
const initialState = {
  isAuthenticated: false,
  error: null,
  signupsuccess: false,
  verifysuccess: false
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case types.EDITPROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload.user
      }
      case SIGNUP_SUCCESS:
        return {
          signupsuccess: true,
        };
      case VERIFY_SUCCESS:
        return {
          verifysuccess: action.payload
        }
    case LOGOUT:
      return { ...state, isAuthenticated: false };
    case ERROR:
      return {...state, error: action.payload}
      case CLEARERROR:
        return {...state, error: action.payload}
    case CLEARSIGNUP:
      return {...state, signupsuccess: false, error: null, verifysuccess: false}
    default:
      return state;
  }
};

export default authReducer;
