import { ACTION_FAIL } from "../actionTypes/state.actionTypes";
import { LOADED, LOADING, RESET_SUCCESS, RESET_UI, CLEARSUCCESSRESET } from "../actionTypes/ui.actionTypes";

const initialState = {
  isLoading: false,
  message: "",
};

const uiReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case LOADING:
      return { ...state, isLoading: true, message: payload };
    case LOADED:
      return { ...state, isLoading: false, message: "" };
    case RESET_UI:
      return { ...state, isLoading: false, message: "UI Reset" };
    case ACTION_FAIL:
      return { ...state, isLoading: false, message: "" };
    case RESET_SUCCESS:
      return {...state, isLoading: false, message: payload}
    case CLEARSUCCESSRESET:
      return {...state, message: ''}
    default:
      return state;
  }
};

export default uiReducer;
