import React from 'react';
import './DrawerPredictionSoFar.css';
import LinearProgress from '@material-ui/core/LinearProgress';

import { withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.grey[200],
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[200],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: props => props.color,
    },
}))(LinearProgress);

const DrawerPredictionSoFar = ({ summary, match }) => {
    return (
        <>
        <div className="drawer__match_title" >
            Predictions So Far
        </div>
        <div className="drawer__match_progresses" >
            {match?.homeTeam?.substring(0, 3)?.toUpperCase() || '-'}<div className="progress" ><BorderLinearProgress color="#1a90ff" variant="determinate" value={summary?.homeTeamWins?.percent || 0} /></div>{`${parseFloat(summary?.homeTeamWins?.percent || 0).toFixed(2)}% (${summary?.homeTeamWins?.count || 0})`}
        </div>
        <div className="drawer__match_progresses" >
            DRW<div className="progress" ><BorderLinearProgress color="#43a047" variant="determinate" value={summary?.draw?.percent || 0} /></div>{`${parseFloat(summary?.draw?.percent || 0).toFixed(2)}% (${summary?.draw?.count || 0})`}
        </div>
        <div className="drawer__match_progresses" >
            {match?.awayTeam?.substring(0, 3)?.toUpperCase() || '-'}<div className="progress" ><BorderLinearProgress color="#ef5350" variant="determinate" value={summary?.awayTeamWins?.percent || 0} /></div>{`${parseFloat(summary?.awayTeamWins?.percent || 0).toFixed(2)}% (${summary?.awayTeamWins?.count || 0})`}
        </div>
        </>
    );
}

export default DrawerPredictionSoFar;