import React from 'react';
import DrawerMatch from './DrawerMatch';
import './DrawerMatch.css';
import './HeadToHeadMatches.css';

const DrawerHTH = ({ fixtures }) => {
    // console.log(fixtures);
    const matches = [];
    fixtures?.forEach(fixture => {
        const match = {
            league: fixture?.league?.name,
            awayScore: fixture?.goals?.away,
            homeScore: fixture?.goals?.home,
            homeTeam: fixture?.teams?.home?.name,
            awayTeam: fixture?.teams?.away?.name,
            date: fixture?.fixture?.date,
            awayTeamFlag: fixture?.teams?.away?.logo,
            homeTeamFlag: fixture?.teams?.home?.logo,
        }
        matches.push(match);
    })
    return (
        <>
        <div className="drawer__match_title" >
            Head To Head Matches
        </div>
        {
           matches.length > 0 && matches.map(match => <DrawerMatch match={match} homeScore={match.homeScore} awayScore={match.awayScore} />)
        }
        {
           matches.length === 0 && <div className="drawer__hth" >−</div>
        }
        </>
    );
}

export default DrawerHTH;