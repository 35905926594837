import React from "react";

const ResultsIcon = ({ active }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="31.541" height="36.373" viewBox="0 0 31.541 36.373">
  <g id="Group_10" data-name="Group 10" transform="translate(-51 -168)">
    <g id="Group_8" data-name="Group 8" transform="translate(0 0.186)">
      <g id="idea" transform="translate(16.994 167.814)">
        <path id="Path_5" data-name="Path 5" d="M146.848,93.5a2.134,2.134,0,0,0,2.131,2.131H150.4a2.134,2.134,0,0,0,2.131-2.131V91.531a1.94,1.94,0,0,1,.776-1.482,5.683,5.683,0,1,0-7.254-.016,2.008,2.008,0,0,1,.8,1.526Zm3.552.71h-1.421a.711.711,0,0,1-.71-.71v-.71h2.842v.71A.711.711,0,0,1,150.4,94.208Zm-4.973-8.525a4.217,4.217,0,0,1,4.262-4.262,4.25,4.25,0,0,1,2.714,7.531,3.407,3.407,0,0,0-1.288,2.415h-2.853a3.432,3.432,0,0,0-1.3-2.427A4.229,4.229,0,0,1,145.427,85.683Z" transform="translate(-102.185 -74.317)" />
        <path id="Path_6" data-name="Path 6" d="M195.425,142.426v2.547a.71.71,0,0,0,1.421,0v-2.547l1.213-1.213a.71.71,0,0,0-1-1l-.918.918-.918-.918a.71.71,0,0,0-1,1Z" transform="translate(-148.632 -130.055)" />
        <circle id="Ellipse_1" data-name="Ellipse 1" cx="0.719" cy="0.719" r="0.719" transform="translate(37.554 3.802)" />
        <path id="Path_7" data-name="Path 7" d="M61.809,15.432l-.011-.011A2.7,2.7,0,0,1,61,13.5,13.5,13.5,0,0,0,40.625,1.882.71.71,0,0,0,41.35,3.1,12.08,12.08,0,0,1,59.581,13.5a4.113,4.113,0,0,0,1.207,2.922l3.13,3.272a.71.71,0,0,1-.271,1.174l-2.152.694a.711.711,0,0,0-.433.962s.771,1.758.777,1.769a.71.71,0,0,1-.341.964l-.612.278a.71.71,0,0,0-.209,1.149l.239.239a.711.711,0,0,1,0,1l-.416.416a.71.71,0,0,0-.208.5,2.134,2.134,0,0,1-2.131,2.131H55.318a4.575,4.575,0,0,0-4.205,3.978H41.2c.51-5.022-1.157-8.187-2.772-11.253-1.477-2.8-3-5.7-3-10.2A12.063,12.063,0,0,1,37.11,7.344a.71.71,0,1,0-1.222-.725A13.483,13.483,0,0,0,34.006,13.5c0,4.851,1.61,7.908,3.167,10.864,1.668,3.166,3.243,6.157,2.523,11.2a.71.71,0,0,0,.7.811H51.766a.71.71,0,0,0,.71-.71c0-1.511,1.241-3.268,2.842-3.268H58.16A3.557,3.557,0,0,0,61.7,29.15l.221-.221a2.135,2.135,0,0,0,.381-2.495,2.132,2.132,0,0,0,.816-2.762l-.439-1,1.411-.455a2.131,2.131,0,0,0,.839-3.522Z" />
      </g>
    </g>
  </g>
</svg>
  );
};

export default ResultsIcon;
