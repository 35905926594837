import axios from "axios";
import { API_URL, APPLICATION_JSON, authAxios } from "../constants/http";

// axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
//   "token"
// )}`;

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem(
    "token"
  )}` }
};

export const getAllMatches = async () => { // user score if filld
  const result = await authAxios
    .get(`${API_URL}/api/fixtures`, config)
    .then((data) => data);
  return result;
};


export const getNextMatches = async () => { // user score if filld
    const result = await authAxios
      .get(`${API_URL}/api/fixtures/next`, config)
      .then((data) => data);
    return result;
};

export const getPrevMatches = async () => { // user score and match score
    const result = await authAxios
      .get(`${API_URL}/api/fixtures/past`, config)
      .then((data) => data);
      // console.log(result);
    return result;
};

export const saveMatches = async (data: any) => {
    const result = await authAxios
      .post(`${API_URL}/api/match-prediction/create`, data, config)
      .then((res) => {
   
          if (res && res.status == 200 && data.onSaveSuccess) {
            data.onSaveSuccess();
          }
          else {
            throw '';
          }
          return res;
      })
      .catch(_err => {
        if (data.onSaveError) {
          data.onSaveError();
        }
      });
    return result;
};
  

export const getPointsSystem = async () => {
    const result = await authAxios
    .get(`${API_URL}/api/points-system/`, config)
    .then((data) => data)
    .catch(err => {
      console.log(err.message);
    });
    return result;
};

export const getMatchStats = async (data: any) => {
    const result = await authAxios
      .post(`${API_URL}/api/prediction/create`, data, config)
      .then((data) => data);
    return result;
};

// handle errors!!