export const ADD_GROUPS = "ADD_GROUPS";

export const ADD_GROUP_POSITION = "ADD_GROUP_POSITION";
export const UPDATE_GROUP_POSITION = "UPDATE_GROUP_POSITION";
export const REMOVE__GROUP_POSITION = "REMOVE__GROUP_POSITION";
export const RESET_POSITIONS = "RESET_POSITIONS";
export const PROCESS_BEST_LOSERS = "PROCESS_BEST_LOSERS";
export const UPDADE_ROUND_16_MATCHES = "UPDADE_ROUND_16_MATCHES";
export const UPDATE_BEST_LOSERS = "UPDATE_BEST_LOSERS";
export const CALCULATE_BEST_LOSERS_MATCHES = "CALCULATE_BEST_LOSERS_MATCHES";
export const RESET_BEST_LOSERS = "RESET_BEST_LOSERS";

export const MATCH_NEXT_ROUND_GAME = "MATCH_NEXT_ROUND_GAME";
export const SAVE_PREDICTION = "SAVE_PREDICTION";

export const UPDATE_PUBLIC_LEAGUE_POSITIONS = 'UPDATE_PRIVATE_LEAGUE_POSITIONS';
export const UPDATE_PRIVATE_LEAGUE_POSITIONS = 'UPDATE_PRIVATE_LEAGUE_POSITIONS';

export const TOGGLE_LOCK_SELECTION = "TOGGLE_LOCK_SELECTION";
export const SET_PREDICTION_STATE = 'SET_PREDICTION_STATE';
export const CREATE_LEAGUE_SUCCESS = 'CREATE_LEAGUE_SUCCESS';
export const CREATE_LEAGUE_LOADING = 'CREATE_LEAGUE_LOADING ';
export const JOIN_LEAGUE_SUCCESS = 'JOIN_LEAGUE_SUCCESS';
export const DELETE_LEAGUE_LOADING = 'DELETE_LEAGUE_LOADING ';
export const DELETE_LEAGUE_SUCCESS = 'DELETE_LEAGUE_SUCCESS';
export const DELETE_USERLEAGUE_LOADING = 'DELETE_USERLEAGUE_LOADING ';
export const DELETE_USERLEAGUE_SUCCESS = 'DELETE_USERLEAGUE_SUCCESS';
export const JOIN_LEAGUE_LOADING = 'JOIN_LEAGUE_LOADING ';
export const GET_LEAGUES_SUCCESS = 'GET_LEAGUES_SUCCESS';
export const GET_LEAGUES_LOADING = 'GET_LEAGUES_LOADING ';
export const GET_SINGLELEAGUES_SUCCESS = 'GET_SINGLELEAGUES_SUCCESS';
export const GET_SINGLELEAGUES_LOADING = 'GET_SINGLELEAGUES_LOADING ';
export const GET_PUBLICLEAGUES_SUCCESS = 'GET_PUBLICLEAGUES_SUCCESS';
export const GET_PUBLICLEAGUES_LOADING = 'GET_PUBLICLEAGUES_LOADING ';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const DELETE_PRIVATELEAGUE_LOADING = 'DELETE_PRIVATELEAGUE_LOADING';
export const DELETE_PRIVATELEAGUE_SUCCESS = 'DELETE_PRIVATELEAGUE_SUCCESS';
export const GET_POINTS_LOADING = 'GET_POINTS_LOADING';
export const GET_POINTS_SUCCESS = 'GET_POINTS_SUCCESS';
export const CLEARALL = 'CLEARALL';
export const CLEARLEAGUE = 'CLEARLEAGUE';
export const SENDMAIL_LOADING = 'SENDMAIL_LOADING';
export const SENDMAIL_SUCCESS = 'SENDMAIL_SUCCESS';
export const EDITPROFILE_LOADING = 'EDITPROFILE_LOADING';
export const EDITPROFILE_SUCCESS = 'EDITPROFILE_SUCCESS';
export const DELETEPROFILE_SUCCESS = 'DELETEPROFILE_SUCCESS';
export const DELETEPROFILE_LOADING = 'DELETEPROFILE_LOADING';
export const ERROR = 'ERROR';
export const TRIGGER_LEAGUE_UPDATE = 'TRIGGER_LEAGUE_UPDATE';