import React from 'react';
import './DrawerMatch.css';
const format = require("date-format");

const DrawerMatch = ({ match, awayScore, homeScore }) => {
    console.log(match)
    return (
        <>
        {match?.league && <div className="drawer__match_league" >{match.league}</div>}
        <div className="drawer__match_container" >
            {match?.homeTeam || ""}
            <div className="drawer__match_image_wrapper" >
                <div className="drawer__match_image" style={{ backgroundImage: `url(${match?.homeTeamFlag})` }} />
            </div>
            <div className="drawer__match_scores" >
                <span>{homeScore?.toString() || "−"}</span>
                <span>-</span>
                <span>{awayScore?.toString() || "−"}</span>
            </div>
            <div className="drawer__match_image_wrapper" >
                <div className="drawer__match_image" style={{ backgroundImage: `url(${match?.awayTeamFlag})` }} />
            </div>
            {match?.awayTeam || ""}
        </div>
        <div className="drawer__match_time" >
            {match?.date ? format("dd/MM/yyyy hh:mm", new Date(match.date)) : "−"}
        </div>
        </>
    );
}

export default DrawerMatch;