import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import eaSports from "../../assets/images/download.png";
import barclays from "../../assets/images/download.png";
import budweiser from "../../assets/images/download.png";
import cocacola from "../../assets/images/download.png";
import nike from "../../assets/images/download.png";
import avertDenison from "../../assets/images/download.png";
import panini from "../../assets/images/download.png";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footerSponsors">
        <img src={eaSports} alt="eaSports" />
        <img src={barclays} alt="barclays" />
        <img src={budweiser} alt="budweiser" />
        <img src={eaSports} alt="eaSports" />
        <img src={cocacola} alt="cocacola" />
        <img src={nike} alt="nike" />
        <img src={avertDenison} alt="avertDenison" />
        <img src={panini} alt="panini" />
      </div> */}
      <div className="footerBottom row">
        <h5>
          Copyright© 2020 All Rights Reserved by <strong>FootballAroundMe</strong>{" "}
        </h5>
        <div className="footerBottom__socialIcons">
          <div className="faCircleContainer">
            <a href="https://www.facebook.com/amigoscup/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon className="faCircle" icon={faFacebookF} />
            </a>
          </div>
          <div className="faCircleContainer">
            <a href="https://www.instagram.com/lahiburim/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon className="faCircle" icon={faInstagram} />
            </a>
          </div>
          <div className="faCircleContainer">
            <a href="https://twitter.com/lahiburim" target="_blank" rel="noreferrer">
              <FontAwesomeIcon className="faCircle" icon={faTwitter} />
            </a>
          </div>
          <div className="faCircleContainer">
            <a href="https://www.linkedin.com/in/shlomi-meiner-520a7a7b/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon className="faCircle" icon={faLinkedinIn} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
