import { IBestLoser } from "../types/predictions";

/*
   1 -  Winner Group 1B	 VS 3rd Group A/D/E/F
   7 -  Winner Group 1C	VS  3rd Group D/E/F
   5 -  Winner Group 1E  VS  3rd Group A/B/C/D
   3 -  Winner Group 1F  VS  3rd Group A/B/C
  */
export const thirdPlaceMap: IBestLoser = {
  //A	B	C	D	= 3A	3D	3B	3C
  ABCD: {
    1: "A",
    7: "D",
    5: "B",
    3: "C",
  },

  //A	B	C	E	= 3A	3E	3B	3C
  ABCE: {
    1: "A",
    7: "E",
    5: "B",
    3: "C",
  },

  //A	B	C	F	= 3A	3F	3B	3C
  ABCF: {
    1: "A",
    7: "F",
    5: "B",
    3: "C",
  },

  //A	B	D	E	=	3D	3E	3A	3B
  ABDE: {
    1: "D",
    7: "E",
    5: "A",
    3: "B",
  },

  //A	B	D	F	= 3D	3F	3A	3B
  ABDF: {
    1: "D",
    7: "F",
    5: "A",
    3: "B",
  },

  //A	B	E	F	= 3E	3F	3B	3A
  ABEF: {
    1: "E",
    7: "F",
    5: "B",
    3: "A",
  },
  //A	C	D	E	=	3E	3D	3C	3A
  ACDE: {
    1: "E",
    7: "D",
    5: "C",
    3: "A",
  },
  //A	C	D	F	= 3F	3D	3C	3A
  ACDF: {
    1: "F",
    7: "D",
    5: "C",
    3: "A",
  },
  //A	C	E	F	= 3E	3F	3C	3A
  ACEF: {
    1: "E",
    7: "F",
    5: "C",
    3: "A",
  },

  // A D E	F	= 3E	3F	3D	3A
  ADEF: {
    1: "E",
    7: "F",
    5: "D",
    3: "A",
  },

  //B	C	D	E	=	3E	3D	3B	3C
  BCDE: {
    1: "E",
    7: "D",
    5: "B",
    3: "C",
  },

  //B	C	D	F	= 3F	3D	3C	3B
  BCDF: {
    1: "F",
    7: "D",
    5: "C",
    3: "B",
  },

  //B	C	E	F	= 3F	3E	3C	3B
  BCEF: {
    1: "F",
    7: "E",
    5: "C",
    3: "B",
  },

  //B	D	E	F	= 3F	3E	3D	3B
  BDEF: {
    1: "F",
    7: "E",
    5: "D",
    3: "B",
  },

  //C	D	E	F	= 3F	3E	3D	3C
  CDEF: {
    1: "F",
    7: "E",
    5: "D",
    3: "C",
  },
};
