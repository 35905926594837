export const round16InitialData = [
  {
    matchId: 1,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },

  {
    matchId: 2,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
  {
    matchId: 3,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
  {
    matchId: 4,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
  {
    matchId: 5,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
  {
    matchId: 6,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
  {
    matchId: 7,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
  {
    matchId: 8,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
];

export const quarterFinalsInitialData = [
  {
    matchId: 1,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },

  {
    matchId: 2,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
  {
    matchId: 3,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
  {
    matchId: 4,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
];

export const semiFinalsInitialData = [
  {
    matchId: 1,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },

  {
    matchId: 2,
    homeTeam: "",
    awayTeam: "",
    homeTeamFlag: "",
    awayTeamFlag: "",
    homeTeamGroupPosition: "",
    awayTeamGroupPosition: "",
    homeTeamId: "",
    awayTeamId: "",
  },
];

export const finalsInitialData = {
  matchId: 1,
  homeTeam: "",
  awayTeam: "",
  homeTeamFlag: "",
  awayTeamFlag: "",
  homeTeamGroupPosition: "",
  awayTeamGroupPosition: "",
  homeTeamId: "",
  awayTeamId: "",
};
