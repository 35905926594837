import { ACTION_FAIL, ACTION_SUCCESS } from "../actionTypes/state.actionTypes";
import * as types from '../actionTypes/predictions';

const initialState = {
  error: false,
  success: false,
  message: "",
  verified: false,
  isOrderSubmitted: false,
  canGetCurrentLocation: false,
  isPaymentComplete: false,
};

const stateReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case ACTION_SUCCESS:
      return { ...state, error: false, success: true, message: payload };
    case ACTION_FAIL:
      return { ...state, error: true, success: false, message: payload };
      case types.CLEAR_SUCCESS:
        return {
          ...state,
          error: false,
          message: '',
        }
    default:
      return state;
  }
};

export default stateReducer;
