import { ACTION_FAIL } from "../actionTypes/state.actionTypes";
import { RESET_UI } from "../actionTypes/ui.actionTypes";
import { Dispatch } from "redux";

export const handleErrorAction = (error: any) => {
  console.log(error.response);
        const code = error.response.status;
        if (code === 401) {
          // actions.auth.promptToSignIn();
          localStorage.clear();
          window.location.href = '/';
          window.location.reload();
        }
  return {
    type: ACTION_FAIL,
    payload:
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.message
        ? error.response.data.errors.message
        : "Sorry , something went wrong",
  };
};

export const resetUtilAction = () => async (
  dispatch: Dispatch
): Promise<any> => {
  return dispatch({
    type: RESET_UI,
  });
};
