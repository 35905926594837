import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Exiticon from '@material-ui/icons/ExitToApp';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link, useLocation, useHistory } from "react-router-dom";
import "./SideBarMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import ContactIcon from "../../assets/icons/ContactIcon";
import CupIcon from "../../assets/icons/CupIcon";
import NewsIcon from "../../assets/icons/NewsIcon";
import PointSystemIcon from "../../assets/icons/PointSystemIcon";
import QuestionIcon from "../../assets/icons/QuestionIcon";
import RankingsIcon from "../../assets/icons/RankingsICon";
import ResultsIcon from "../../assets/icons/ResultsIcon";
import logo from "../../assets/images/logo.png";
import logotwo from "../../assets/images/logo.png";
import Usericon from '../../assets/icons/usericon';
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth = '20vh';

const useStyles = makeStyles((theme) => ({
  list: {
    width: drawerWidth,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-betewwn'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuButton: {
    color: 'black',
  },
  hide: {
    display: 'none',
  },  
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  logo: {
    width: 50
  }
}));

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const pathname = history.location.pathname;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onClick = () => {
    document.getElementById("root")?.scrollTo(0,0);
  }


  return (
    <div className={classes.root}>
    <CssBaseline />
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar style={{width: '100%', display: 'flex', 
    alignItems: 'center', justifyContent: 'space-between'}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Link onClick={onClick} to="/predictions">
           <img src={logotwo} className={classes.logo} alt="logo" />
         </Link>
         <Link onClick={onClick} to="/logout">
           <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <Exiticon />
        </IconButton>
         </Link>
         
      </Toolbar>
    </AppBar>
          <SwipeableDrawer
            anchor='left'
            open={open}
            onClose={handleDrawerClose}
            // onOpen={onOpenside}
          >
    <div
      className={clsx(classes.list)}
      role="presentation"
    >
      <div style={{position: 'fixed', top: 0, right: 0, width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CloseIcon style={{color: '#fff'}} onClick={handleDrawerClose}/>
      </div>
       <div className="sidebarMenu__item">
         <Link onClick={onClick} to="/predictions">
           <img src={logo} alt="logo" />
         </Link>
       </div>

       <div className={`sidebarMenu__item ${(pathname === "/leaderboard") && "sidebarMenu__item_active"}`} >
         <Link onClick={onClick} to="/leaderboard">
           <CupIcon active={location.pathname === "/leaderboard"}  style={{width: 10}}/>
           <h3>Leaderboard</h3>
         </Link>
       </div>

       <div className={`sidebarMenu__item ${(pathname === "/predictions") && "sidebarMenu__item_active"}`} >
         <Link onClick={onClick} to="/predictions">
           <RankingsIcon active={location.pathname === "/predictions"} />
           <h3>Predictions</h3>
         </Link>
       </div>

       <div className={`sidebarMenu__item ${(pathname === "/results") && "sidebarMenu__item_active"}`} >
         <Link onClick={onClick} to="/results">
            <PointSystemIcon active={location.pathname === "/results"} />
           {/* <ResultsIcon active={location.pathname === "/results"} /> */}
           <h3>Scores Guesses</h3>
         </Link>
       </div>

       <div className={`sidebarMenu__item ${(pathname === "/pointsystem") && "sidebarMenu__item_active"}`}>
         <Link onClick={onClick} to="/pointsystem">
           {/* <PointSystemIcon active={location.pathname === "/pointsystem"} /> */}
           <ResultsIcon active={location.pathname === "/pointsystem"} />
           <h3>Points System</h3>
         </Link>
       </div>

      {/* <div className="sidebarMenu__item">
         <Link to="/results">
         <QuestionIcon
            active={location.pathname === "/results"}
            color="#2b2b2b"
          />
          <h3>About</h3>
        </Link>
      </div> */}

      <div className={`sidebarMenu__item ${(pathname === "/contactus") && "sidebarMenu__item_active"}`}>
        <Link onClick={onClick} to="/contactus">
          <ContactIcon active={location.pathname === "/contactus"} />
          <h3>Contact</h3>
        </Link>
      </div>
      <div className={`sidebarMenu__item ${(pathname === "/profile") && "sidebarMenu__item_active"}`}>
        <Link to="/profile">
          <Usericon />
          <h3>Profile</h3>
        </Link>
      </div>
      <div className="sidebarMenu__item">
        <Link onClick={onClick}  to="/logout">
          <FontAwesomeIcon icon={faSignOutAlt} />
          <h3>Logout</h3>
        </Link>
      </div>
    </div>
          </SwipeableDrawer>
          </div>
  )
  }