import React from "react";

const NewsIcon = ({ active }) => {
  return (
    <svg
      id="XMLID_323_"
      xmlns="http://www.w3.org/2000/svg"
      width="38.861"
      height="38.861"
      viewBox="0 0 38.861 38.861"
    >
      <g id="XMLID_1291_">
        <path
          id="XMLID_1351_"
          d="M38.1,0H7.438a.759.759,0,0,0-.759.759V4.1H4.1a.759.759,0,0,0-.759.759v4.1H.759A.759.759,0,0,0,0,9.715V34.762a4.1,4.1,0,0,0,4.1,4.1H34.762a4.1,4.1,0,0,0,4.1-4.1v-34A.759.759,0,0,0,38.1,0Zm-.759,34.762a2.584,2.584,0,0,1-2.581,2.581H7.28A4.079,4.079,0,0,0,8.2,34.762V24.516a.759.759,0,1,0-1.518,0V34.762a2.581,2.581,0,0,1-5.161,0V10.474H3.34V34a.759.759,0,0,0,1.518,0V5.617H6.679V17.685a.759.759,0,1,0,1.518,0V1.518H37.343Z"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1376_"
          d="M136.759,72.983h23.377a.759.759,0,0,0,.759-.759V66.759a.759.759,0,0,0-.759-.759H136.759a.759.759,0,0,0-.759.759v5.465A.759.759,0,0,0,136.759,72.983Zm.759-5.465h21.859v3.947H137.518Z"
          transform="translate(-125.678 -60.991)"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1459_"
          d="M136.759,202.091h11.689a.759.759,0,0,0,.759-.759V186.759a.759.759,0,0,0-.759-.759H136.759a.759.759,0,0,0-.759.759v14.573A.759.759,0,0,0,136.759,202.091Zm.759-14.573h10.171v13.055H137.518Z"
          transform="translate(-125.678 -171.883)"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1460_"
          d="M346.8,186h-8.045a.759.759,0,1,0,0,1.518H346.8a.759.759,0,1,0,0-1.518Z"
          transform="translate(-312.346 -171.883)"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1476_"
          d="M346.8,234h-8.045a.759.759,0,1,0,0,1.518H346.8a.759.759,0,1,0,0-1.518Z"
          transform="translate(-312.346 -216.239)"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1533_"
          d="M346.8,282h-8.045a.759.759,0,1,0,0,1.518H346.8a.759.759,0,1,0,0-1.518Z"
          transform="translate(-312.346 -260.596)"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1535_"
          d="M346.8,330h-8.045a.759.759,0,1,0,0,1.518H346.8a.759.759,0,1,0,0-1.518Z"
          transform="translate(-312.346 -304.953)"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1545_"
          d="M346.8,378h-8.045a.759.759,0,1,0,0,1.518H346.8a.759.759,0,1,0,0-1.518Z"
          transform="translate(-312.346 -349.31)"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1550_"
          d="M136.759,427.518h23.377a.759.759,0,1,0,0-1.518H136.759a.759.759,0,1,0,0,1.518Z"
          transform="translate(-125.678 -393.666)"
          fill="#2b2b2b"
        />
        <path
          id="XMLID_1572_"
          d="M89.259,268a.759.759,0,1,0,.537.222A.764.764,0,0,0,89.259,268Z"
          transform="translate(-81.783 -247.659)"
          fill="#2b2b2b"
        />
      </g>
    </svg>
  );
};

export default NewsIcon;
