import React from "react";

const CupIcon = ({ active }) => {
  return (
    <svg viewBox="0 0 33.659 41.032" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 17">
        <path
          transform="translate(-210.83 -415.78)"
          d="M229.329,452h-3.335a.8.8,0,1,0,0,1.6h3.335a.8.8,0,1,0,0-1.6Z"
          data-name="Path 18"
        />
        <path
          transform="translate(-46)"
          d="M78.858,6.411H74.049V4.671A2.4,2.4,0,0,0,73.248,0H52.411a2.4,2.4,0,0,0-.8,4.671V6.411H46.8a.8.8,0,0,0-.8.8v5.61a6.419,6.419,0,0,0,6.411,6.411h.678q.231.4.5.789a11.274,11.274,0,0,0,4.453,3.8,2.4,2.4,0,0,0,1.8,2.612c-.963,3.208-2.674,6.584-4.7,6.584H53.213a.8.8,0,0,0-.8.7l-.8,6.411a.8.8,0,0,0,.8.9H73.248a.8.8,0,0,0,.8-.9l-.8-6.411a.8.8,0,0,0-.8-.7H70.523c-1.227,0-2.264-1.288-2.917-2.369a19.043,19.043,0,0,1-1.784-4.215,2.4,2.4,0,0,0,1.8-2.612,11.273,11.273,0,0,0,4.454-3.8c.177-.257.341-.52.5-.788h.678a6.419,6.419,0,0,0,6.411-6.411V7.213a.8.8,0,0,0-.8-.8ZM47.6,12.823V8.014H51.61v5.67a11.124,11.124,0,0,0,.721,3.945A4.813,4.813,0,0,1,47.6,12.823ZM72.34,39.43H53.319l.6-4.808H71.739Zm-4.976-6.411H58.3a9.8,9.8,0,0,0,1.126-1.534,21.264,21.264,0,0,0,2.065-4.978h2.688a21.273,21.273,0,0,0,2.061,4.972,9.818,9.818,0,0,0,1.129,1.54ZM65.234,24.9H60.425a.8.8,0,1,1,0-1.6h4.808a.8.8,0,1,1,0,1.6Zm1.679-2.52a2.4,2.4,0,0,0-1.679-.685H60.425a2.4,2.4,0,0,0-1.679.685,9.588,9.588,0,0,1-5.534-8.7V4.808h6.01a.8.8,0,1,0,0-1.6H52.411a.8.8,0,1,1,0-1.6H73.248a.8.8,0,1,1,0,1.6H66.436a.8.8,0,0,0,0,1.6h6.011v8.876A9.588,9.588,0,0,1,66.913,22.384Zm11.144-9.561a4.813,4.813,0,0,1-4.728,4.806,11.124,11.124,0,0,0,.721-3.945V8.014h4.007Z"
          data-name="Path 19"
        />
        <path
          transform="translate(-229.97 -36.794)"
          d="M246.8,41.6a.8.8,0,0,0,0-1.6h0a.8.8,0,1,0,0,1.6Z"
          data-name="Path 20"
        />
        <path
          transform="translate(-219.77 -101.92)"
          d="M237.49,117.213H237.4V111.6a.8.8,0,0,0-1.2-.694l-.889.513a.8.8,0,0,0,.488,1.491v4.3h-.087a.8.8,0,0,0,0,1.6h1.777a.8.8,0,0,0,0-1.6Z"
          data-name="Path 21"
        />
      </g>
    </svg>
  );
};

export default CupIcon;
