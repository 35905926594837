import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import "./App.css";
import AppRouter from "./appRouter.jsx";

const App = () => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
        {/* <Interceptor/> */}
      </PersistGate>
    </Provider>
  );
};

export default App;
