import React, { useEffect, useRef } from 'react';
import { Route } from "react-router-dom";
import SideBarMenu from "../SideBarMenu/SideBarMenu";
import Sidebar from '../SideBarMenu/swipable';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Scroller from '../../pages/scroller';
import { useSelector } from 'react-redux';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Person } from '@material-ui/icons';
const drawerWidth = '20vh';
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      //display: 'flex',
    },
    alert: {
      padding: 0,
      marginTop: 8,
      height: 'auto',
      paddingLeft: 8,
      paddingRight: 8,
      direction: "rtl",
      [theme.breakpoints.down('sm')]: {
        height: 56,
        marginTop: 56,
        marginBottom: -16,
      },
    },
    alertText: {
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      '&strong': {
        fontWeight: 700,
      }
    },
    alertAvatar: {
      width: 32,
      height: 32,
      marginTop: -12,
      borderRadius: 32,
      backgroundColor: '#43a047',
    },
    textMe: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular,
    },
    appBar: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth})`,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      padding: '0 !important'
    },
    drawerPaper: {
      width: drawerWidth,
    },
    mobilesidebar: {
      [theme.breakpoints.up('md')]: {
        display: 'none'
      },
    },
    websidebar: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      backgroundColor: theme.palette.background.default,
      width: `calc(100% - ${drawerWidth})`,
      [theme.breakpoints.up('md')]: {
        marginLeft: '20vh',
        paading: '0 !important'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
    },
  }),
);
const AppRoute = ({ children, ...rest }) => {
  const classes = useStyles();
  const divRef = useRef();
  const topContainer = useRef();
  useEffect(() => {
    divRef.current.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.authManager.user);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return (
          <>
            <div className={classes.root} ref={topContainer} >
              <div className={classes.mobilesidebar}><Sidebar /></div>
              <div className={classes.websidebar}><SideBarMenu /></div>
              <main className={classes.content} ref={divRef} >
                <ListItem className={classes.alert}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.alertAvatar} >
                      <Person />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${user.firstName} ${user.lastName}`}
                    primaryTypographyProps={{
                      style: {
                        fontSize: 14,
                        marginTop: -12,
                        marginRight: -12,
                        textAlign: 'right',
                      }
                    }}
                    secondary={user.email}
                    secondaryTypographyProps={{
                      style: {
                        fontSize: 12,
                        marginTop: -4,
                        marginRight: -12,
                        textAlign: 'right',
                      }
                    }}
                  />
                  {/* <span className={classes.alertText} >
                    Contact me: <a href='mailto:shlomi@footballaroundme.com' ><strong>shlomi@footballaroundme.com</strong></a>
                  </span> */}
                </ListItem>
                {/* <Alert severity="success" variant="filled" className={classes.alert} >
                  <b>All Good! For any issue contact me shlomi@footballaroundme.com</b>
                </Alert> */}
                {children}
              </main>
            </div>
          </>
        );
      }}
    />
  );
};
export default AppRoute;