import { Dispatch } from "redux";
import { login, register, socialLogin, reset, resetpass, verify} from "../../services/authService";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_SUCCESS,
  ERROR,
  CLEARSIGNUP,
  VERIFY_SUCCESS
} from "../actionTypes/auth.actionTypes";
import * as types from '../actionTypes/predictions';
import { LOADED, LOADING, RESET_UI, RESET_SUCCESS, CLEARSUCCESSRESET } from "../actionTypes/ui.actionTypes";
import { handleErrorAction } from "./UtilsAction";

export const registerAction = (data: IsignUp, actions?: any) => (dispatch: Dispatch) => {
  dispatch({ type: CLEARSIGNUP });
  dispatch({ type: LOADING, payload: "Creating account , please wait..." });
  return register(data).then(
    (res) => {
        dispatch({ type: LOADED });
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
        if (actions?.onSuccess) {
          actions.onSuccess();
        }
    },
    (error) => {
      if (actions?.onError) {
        actions.onError(error?.response?.data?.errors?.message || "Sorry , something went wrong");
      }
      dispatch(handleErrorAction(error));
    }
  );
};



export const loginAction = (data: ILogin, actions: any) => (dispatch: Dispatch) => {
  dispatch({ type: CLEARSIGNUP });
  dispatch({ type: LOADING, payload: "Logging you in , please wait..." });
  return login(data).then(
    (res) => {
        dispatch({ type: LOADED });
        dispatch({
          type: types.CLEARALL,
      })
        // console.log(res.data);
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
    },
    (error) => {
      if (actions?.onError) {
        actions.onError(error?.response?.data?.errors?.message || "Sorry , something went wrong");
      }
      dispatch(handleErrorAction(error));
    }
  );
};


export const resetAction = (data: IForgot) => (dispatch: Dispatch) => {
  dispatch({ type: CLEARSIGNUP });
  dispatch({ type: LOADING, payload: "sending email , please wait..." });
  return reset(data).then(
    (res) => {
        dispatch({ type: LOADED });
        console.log(res.data);
        dispatch({
          type: RESET_SUCCESS,
          payload: res.data.message
        });
    },
    (error) => {
      dispatch(handleErrorAction(error));
      dispatch({ type: ERROR, payload: {
        type: 'emailsenderror',
        data: error.response.data
      } });
    }
  );
};

export const verifyAccount = (data: IVerify) => (dispatch: Dispatch) => {
  dispatch({ type: CLEARSIGNUP });
  dispatch({ type: LOADING, payload: "verifying account , please wait..." });
  return verify(data).then(
    (res) => {
      console.log(res.data);
        dispatch({ type: LOADED });
        dispatch({
          type: RESET_SUCCESS,
          payload: res.data.message
        });
    },
    (error) => {
      dispatch(handleErrorAction(error));
      dispatch({ type: ERROR, payload: {
        type: 'verifyaccounterror',
        data: error.response.data
      } });
    }
  );
};

export const resetPassword = (data: IConfirm) => (dispatch: Dispatch) => {
  const retries1 = localStorage.getItem('retries') || "0";
  if (parseInt(retries1) >= 3) {
    localStorage.removeItem('retries');
    window.location.href = '/reset-password/resend'
  }
  dispatch({ type: CLEARSIGNUP });
  dispatch({ type: LOADING, payload: "confirming , please wait..." });
  return resetpass(data).then(
    (res) => {
        dispatch({ type: LOADED });
        // console.log(res.data);
        dispatch({
          type: RESET_SUCCESS,
          payload: res.data.message
        });
    },
    (error) => {
      if (error.response.data.errors?.message === 'Please enter a valid verification code') {
        const retries = localStorage.getItem('retries') || "0";
        localStorage.setItem('retries', (parseInt(retries) + 1).toString())
      }
      dispatch(handleErrorAction(error));
      dispatch({ type: ERROR, payload: {
        type: 'reseterror',
        data: error.response.data
      } });
    }
  );
};

export const Clearsuccessreset = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEARSUCCESSRESET });
};



export const Clearsuccess = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEARSIGNUP });
};



export const socialLoginAction = (data: ISocialLogin) => (
  dispatch: Dispatch
) => {
  dispatch({ type: CLEARSIGNUP });
  dispatch({ type: LOADING, message: "Logging you in , please wait..." });
  return socialLogin(data).then(
    (res) => {
        dispatch({ type: LOADED });
        dispatch({
          type: types.CLEARALL,
      })
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        // window.location.reload();
    },
    (error) => {
      dispatch(handleErrorAction(error));
    }
  );
};

export const logoutAction = () => async (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: CLEARSIGNUP });
  return dispatch({
    type: LOGOUT,
  });
};

export const resetLoadingAction = () => async (
  dispatch: Dispatch
): Promise<any> => {
  return dispatch({
    type: RESET_UI,
  });
};
