import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import SideBarMenu from "../SideBarMenu/SideBarMenu";
import AppLayout from './AppLayout.jsx';
import Footer from "../Footer/Footer";
import LoadingOverlay from "react-loading-overlay";

import "./AppLayout.css";

const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector(
    (state) => state.authManager.isAuthenticated
  );

  const message = useSelector((state) => state.uiManager.message);
  const isLoading = useSelector((state) => state.uiManager.isLoading);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <>
            <LoadingOverlay active={isLoading} spinner text={message}>
              <AppLayout>
                 {children}
                  <Footer />
              </AppLayout>
             
            </LoadingOverlay>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
