import React from 'react';
import './DrawerMatch.css';
import './DrawerLastFiveMatches.css';
const format = require("date-format");

const DrawerLastFiveMatches = ({ awayLastFive, homeLastFive, match }) => {

    // console.log(awayLastFive);
    // console.log(homeLastFive);

    const homeScoresSummary = homeLastFive?.map(home => {
        if (home?.goals?.home === home?.goals?.away) {
            return "drew";
        }
        if (home?.teams?.home?.name?.toUpperCase() === match?.homeTeam?.toUpperCase()) {
            if (home?.teams?.home?.winner) {
                return "won"
            } else {
                return "lost"
            }
        }
        if (home?.teams?.away?.name?.toUpperCase() === match?.homeTeam?.toUpperCase()) {
            if (home?.teams?.away?.winner) {
                return "won"
            } else {
                return "lost"
            }
        }
    }) || [];

    const awayScoresSummary = awayLastFive?.map(away => {
        if (away?.goals?.home === away?.goals?.away) {
            return "drew";
        }
        if (away?.teams?.home?.name?.toUpperCase() === match?.awayTeam?.toUpperCase()) {
            if (away?.teams?.home?.winner) {
                return "won"
            } else {
                return "lost"
            }
        }
        if (away?.teams?.away?.name?.toUpperCase() === match?.awayTeam?.toUpperCase()) {
            if (away?.teams?.away?.winner) {
                return "won"
            } else {
                return "lost"
            }
        }
    }) || [];

    const fixtureEntries = [];
    [0, 1, 2, 3, 4].forEach(i => {
        const entry = {};
        if (homeLastFive?.[i] || awayLastFive?.[i]) {
            console.log( homeLastFive?.[i] ,awayLastFive?.[i]?.score.fulltime.home)
            entry.home = homeLastFive?.[i] && homeLastFive?.[i]?.score.fulltime.home != null ? {
                league: homeLastFive?.[i]?.league?.name,
                date: homeLastFive?.[i]?.fixture?.date,
                homeTeam: homeLastFive?.[i]?.teams?.home?.name,
                homeScore: homeLastFive?.[i]?.goals?.home,
                homeFlag: homeLastFive?.[i]?.teams?.home?.logo,
                awayTeam: homeLastFive?.[i]?.teams?.away.name,
                awayScore: homeLastFive?.[i]?.goals?.away,
                awayFlag: homeLastFive?.[i]?.teams?.away?.logo,
            } : null
            entry.away = awayLastFive?.[i] && awayLastFive?.[i]?.score.fulltime.home!= null? {
                league: awayLastFive?.[i]?.league?.name,
                date: awayLastFive?.[i]?.fixture?.date,
                homeTeam: awayLastFive?.[i]?.teams?.home?.name,
                homeScore: awayLastFive?.[i]?.goals?.home,
                homeFlag: awayLastFive?.[i]?.teams?.home?.logo,
                awayTeam: awayLastFive?.[i]?.teams?.away.name,
                awayScore: awayLastFive?.[i]?.goals?.away,
                awayFlag: awayLastFive?.[i]?.teams?.away?.logo,
            } : null
        }
        fixtureEntries.push(entry);
    });

    return (
        <>
        <div className="drawer__match_title" >
            Last Five Matches
        </div>
        <div className="drawer__lfm" >
            <div className="drawer__lfm_teams" >
                <div className="team" >
                    {match?.homeTeam}
                    <div className="drawer__lfm_teams_image_wrapper" >
                        <div className="drawer__lfm_teams_image" style={{ backgroundImage: `url(${match?.homeTeamFlag})` }} />
                    </div>
                </div>
                <div className="team" >
                    <div className="drawer__lfm_teams_image_wrapper" >
                        <div className="drawer__lfm_teams_image" style={{ backgroundImage: `url(${match?.awayTeamFlag})` }} />
                    </div>
                    {match?.awayTeam}
                </div>
            </div>
        </div>
        <div className="drawer__lfm_results" >
            <div className="results results_left" >
                {
                    homeScoresSummary?.slice(0,5).map(hSS => {
                        return <div className={`result ${hSS}`} >{hSS?.substring(0,1)}</div>
                    })
                }
            </div>
            <div className="results results_right" >
                {
                    awayScoresSummary?.slice(0,5).map(aSS => {
                        return <div className={`result ${aSS}`} >{aSS?.substring(0,1)}</div>
                    })
                }
            </div>
        </div>
        {
            fixtureEntries.map((data, index) => {
                const home = data.home;
                const away = data.away;
                return (
                  <div className={`drawer__lfm_matches ${ index % 2 === 1 ? 'odd' : 'even'}`} >
                    <div className="match_lfm match__left" >
                        {
                        home &&
                        <>
                        <div className="match_label" >{home?.league}</div>
                        <div className="match_teams" >
                            <div className="match_team" >
                                <div className="match_teams_image_wrapper" >
                                    <div className="match_teams_image" style={{ backgroundImage: `url(${home?.homeFlag})` }} />
                                </div>
                                {home?.homeTeam?.substring(0,3)?.toUpperCase() || '-'}
                            </div>
                            <div className="match_result" >
                                <span>{home?.homeScore}</span>
                                <span>-</span>
                                <span>{home?.awayScore}</span>
                            </div>
                            <div className="match_team" >
                                <div className="match_teams_image_wrapper" >
                                    <div className="match_teams_image" style={{ backgroundImage: `url(${home?.awayFlag})` }} />
                                </div>
                                {home?.awayTeam?.substring(0,3)?.toUpperCase() ||  '-'}
                            </div>
                        </div>
                        <div className="match_label" >
                            {home?.date ? format("dd/MM/yyyy hh:mm", new Date(home.date)) : "−"}
                        </div>
                        </>
                        }
                    </div>
                    {/* <div className="match_indicator" >
                        <div className="color" style={{ backgroundColor: 'green' }} />
                        <div className="color" style={{ backgroundColor: 'red' }} />
                    </div> */}
                    <div className="match_lfm match__right" >
                        {
                        away &&
                        <>
                        <div className="match_label" >{away?.league}</div>
                        <div className="match_teams" >
                            <div className="match_team" >
                                <div className="match_teams_image_wrapper" >
                                    <div className="match_teams_image" style={{ backgroundImage: `url(${away?.homeFlag})` }} />
                                </div>
                                {away?.homeTeam?.substring(0,3)?.toUpperCase() || '-'}
                            </div>
                            <div className="match_result" >
                                <span>{away?.homeScore}</span>
                                <span>-</span>
                                <span>{away?.awayScore}</span>
                            </div>
                            <div className="match_team" >
                                <div className="match_teams_image_wrapper" >
                                    <div className="match_teams_image" style={{ backgroundImage: `url(${away?.awayFlag})` }} />
                                </div>
                                {away?.awayTeam?.substring(0,3)?.toUpperCase() || '-'}
                            </div>
                        </div>
                        <div className="match_label" >
                            {away?.date ? format("dd/MM/yyyy hh:mm", new Date(away.date)) : "−"}
                        </div>
                    </>
                    }
                    </div>
                   </div>
                )
            })
        }
        </>
    );
}

export default DrawerLastFiveMatches;