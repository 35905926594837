import { TRIGGER_LEAGUE_UPDATE } from "../actionTypes/predictions";
import { EDIT_TOP_MATCHES, EDIT_NEXT_MATCHES, LOADINGLIVE, EDIT_MATCHES_POINTS, EDIT_PREV_MATCHES, CLEAR_PRED,TOP_MATCHES_TEMP } from "../actionTypes/results.actionTypes";

const initialState = {
  nextMatches: [],
  topMatches: [],
  points: {},
  prevMatches: [],
  loading: false,
  triggerLeagueUpdate: false,
  topMatchesTemp: [],
};

const resultsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case EDIT_NEXT_MATCHES:
      return {
        ...state,
        nextMatches: action.payload.nextMatches,
        loading: false
      };
    case TRIGGER_LEAGUE_UPDATE:
      return {
        ...state,
        triggerLeagueUpdate: action.payload.trigger,
      };
    case EDIT_TOP_MATCHES:
      return {
        ...state,
        topMatches: action.payload.topMatches,
        loading: false
      };
    case TOP_MATCHES_TEMP:
      return {
        ...state,
        topMatchesTemp: action.payload.topMatches,
        // loading: false
      };
    case CLEAR_PRED:
      return {
        ...state,
        nextMatches: [],
      }
    case LOADINGLIVE:
      return {
        ...state,
        loading: action.payload
      }
    case EDIT_PREV_MATCHES:
      return {
        ...state,
        prevMatches: action.payload.prevMatches
      };
    case EDIT_MATCHES_POINTS:
      const points = action.payload.points.reduce((acc: any, val: any) => {
        val.scores.forEach((pointScores: any) => {
          acc[`${pointScores.score1}${pointScores.score2}`] = val.points;
          acc[`${pointScores.score2}${pointScores.score1}`] = val.points;
        })
        return acc;
      }, {})
      return {
        ...state,
        points
      };
    default:
      return state;
  }
};

export default resultsReducer;
