import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f6f6f6',
      paper: '#fff'
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontFamily: 'Montserrat',
    },
    h2: {
      fontFamily: 'Montserrat',
    },
    h3: {
      fontFamily: 'Montserrat',
    },
    h4: {
      fontFamily: 'Montserrat',
    },
    h5: {
      fontFamily: 'Montserrat',
    },
    h6: {
      fontFamily: 'Montserrat',
    },
    subtitle1: {
      fontFamily: 'Montserrat',      
    },
    subtitle2: {
      fontFamily: 'Montserrat',      
    },
    body1: {
      fontFamily: 'Montserrat', 
    },
    body2: {
      fontFamily: 'Montserrat', 
    },
    caption: {
      fontFamily: 'Montserrat', 
    },
    overline: {
      fontFamily: 'Montserrat', 
    },
  }
});

export default theme;