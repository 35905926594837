import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import { createBrowserHistory as createHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";

export const history = createHistory();
let composeFn: any = compose;
if (process.env.NODE_ENV === "development") composeFn = composeWithDevTools;

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "stateManager",
    "predictionManager",
    "prediction",
    "resultsReducer",
  ],
};
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

let middleware = [thunk];
let enhancers = [applyMiddleware(...middleware)];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, {}, composeFn(...enhancers));

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
