import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import DrawerMatch from './DrawerMatch';
import DrawerOdds from './DrawerOdds';
import DrawerHTH from './HeadToHeadMatches';
import DrawerStandings from './DrawerStandings';
import DrawerLastFiveMatches from './DrawerLastFiveMatches';
import DrawerPredictionsSoFar from './DrawerPredictionSoFar';
import DrawerCharts from './DrawerCharts';
import './ResultsDrawer.css';
import { CircularProgress } from '@material-ui/core';
import { API_URL, authAxios } from "../../constants/http";
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: 300
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#f5f5f5',
  },
  drawerContainer: {
    overflow: 'auto',
  },
}));


export default function ResultsDrawer({ isOpen, handleClose, match }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [matchStats, setMatchStats] = React.useState(null);
  const [matchHeadToHead, setMatchHeadToHead] = React.useState(null);
  const [thisHomeLastFiveMatches, setThisHomeLastFiveMatches] = React.useState([]);
  const [thisAwayLastFiveMatches, setThisAwayLastFiveMatches] = React.useState([]);

  useEffect( async () => {
    if (isOpen) {
      setLoading(true);
      setMatchStats(null);
      setMatchHeadToHead(null);
      setThisHomeLastFiveMatches([]);
      setThisAwayLastFiveMatches([]);
      try {
        const thisMatchStats = await authAxios.get(`${API_URL}/api/match-prediction/summary/${match?.id}`);
        const thisMatchHeadToHead = await authAxios.get(`${API_URL}/api/fixtures/${match?.id}/headtohead`);
        let thisHomeLastFiveMatches, thisAwayLastFiveMatches;
        if (match?.homeTeamId) {
          thisHomeLastFiveMatches = await authAxios.get(`${API_URL}/api/teams/${match?.homeTeamId}/lastfive`);
        }
        if (match?.awayTeamId) {
          thisAwayLastFiveMatches = await authAxios.get(`${API_URL}/api/teams/${match?.awayTeamId}/lastfive`);
        }
        setMatchStats(thisMatchStats.data);
        setMatchHeadToHead(thisMatchHeadToHead.data);
        if (match?.homeTeamId) {
          setThisHomeLastFiveMatches(thisHomeLastFiveMatches.data);
        }
        if (match?.awayTeamId) {
          setThisAwayLastFiveMatches(thisAwayLastFiveMatches.data);
        }
        setLoading(false);
      } catch (_err) {
        setLoading(false); 
        // console.log(_err.message);
      }
    }
  }, [isOpen, match?.id]);

  // console.log(match);

  return (
      <div className={classes.root}>
        <CssBaseline />
        <SwipeableDrawer
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
          open={isOpen}
          onClose={handleClose}
        >
      <div style={{position: 'fixed', top: 0, left: 0, width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CloseIcon style={{color: '#fff'}} onClick={handleClose}/>
      </div>
          {
            loading ?
            <div className="results__drawer_progress" >
              <CircularProgress className="progress"  size={48} thickness={4} />
            </div>
            :
            <>
              <DrawerMatch match={match} awayScore={match?.goals?.away} homeScore={match?.goals?.home} />
              <DrawerPredictionsSoFar match={match} summary={matchStats?.predictionSummary} />
              <DrawerCharts scores={matchStats?.homeWinsSummary} match={match} type="home" color="#1a90ff" />
              <DrawerCharts scores={matchStats?.drawSummary} match={match} type="draw" color="#43a047" />
              <DrawerCharts scores={matchStats?.awayWinsSummary} match={match} type="away" color="#ef5350" />
              <DrawerHTH fixtures={matchHeadToHead?.headtohead?.fixtures || []} />
              <DrawerLastFiveMatches match={match} awayLastFive={thisAwayLastFiveMatches?.response?.fixtures || []} homeLastFive={thisHomeLastFiveMatches?.response?.fixtures || []} />
              {/* <DrawerStandings/>
              <DrawerOdds/> */}
            </>
          }
        </SwipeableDrawer>
      </div>
  );
}