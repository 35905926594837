import * as types from '../actionTypes/predictions';
import { ACTION_FAIL } from "../actionTypes/state.actionTypes";
import { LOGOUT } from "../actionTypes/auth.actionTypes";
const initialState = {
  positions: [],
  bestlosers: [],
  quaters: [],
  semis: [],
  finals: [],
  winner: [],
  knockout: [],
  bestsixteen: [],
  dataChanged: false,
  createleagueloading: false,
  createleaguesuccess: false,
  getleaguesloading: false,
  getleaguessuccess: null,
  getleaguessuccessdata: null,
  getpublicleaguesloading: false,
  getpublicleaguessuccess: null,
  publicUserPositions: null,
  privateUserPositions: null,
  getpublicleaguessuccessdata: null,
  joinleagueloading: false,
  joinleaguesuccess: false,
  getsingleleaguesloading: false,
  getsingleleaguessuccess: null,
  deleteleagueloading: false,
  deleteleaguesuccess: false,
  deleteuserleagueloading: false,
  deleteuserleaguesuccess: false,
  deleteprivateleaguesuccess: false,
  deleteprivateleagueloading: false,
  getpointsloading: false,
  getpointssuccess: null,
  getsingleleaguessuccessdata: null,
  sendmailsuccess: false,
  sendmailloading: false,
  editprofileloading: false,
  editprofilesuccess: null,
  deleteprofileloading: false,
  deleteprofilesuccess: null,
  error: null
  
};

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return { state };
      case types.ERROR:
        return {...state, error: action.payload}
      case types.CLEARALL:
        return { 
          ...state,
          positions: [],
  bestlosers: [],
  quaters: [],
  semis: [],
  finals: [],
  winner: [],
  knockout: [],
  bestsixteen: [],
  dataChanged: false,
  createleagueloading: false,
  createleaguesuccess: false,
  getleaguesloading: false,
  getleaguessuccess: null,
  getleaguessuccessdata: null,
  getpublicleaguesloading: false,
  getpublicleaguessuccess: null,
  publicUserPositions: null,
  privateUserPositions: null,
  getpublicleaguessuccessdata: null,
  joinleagueloading: false,
  joinleaguesuccess: false,
  getsingleleaguesloading: false,
  getsingleleaguessuccess: null,
  deleteleagueloading: false,
  deleteleaguesuccess: false,
  deleteuserleagueloading: false,
  deleteuserleaguesuccess: false,
  deleteprivateleaguesuccess: false,
  deleteprivateleagueloading: false,
  getpointsloading: false,
  getpointssuccess: null,
  getsingleleaguessuccessdata: null,
  editprofileloading: false,
  editprofilesuccess: null,
  deleteprofileloading: false,
  deleteprofilesuccess: null,
         };
      case types.SET_PREDICTION_STATE:
        return {
          ...state,
          dataChanged: true,
          ...action.payload
        };
        case types.EDITPROFILE_LOADING:
          return {
            ...state,
            editprofileloading: action.payload,
            error: null
          };
        case types.EDITPROFILE_SUCCESS:
          return {
            ...state,
            editprofilesuccess: action.payload,
            editprofileloading: false,
            error: null
          };
          case types.DELETEPROFILE_LOADING:
          return {
            ...state,
            deleteprofileloading: action.payload,
            error: null
          };
        case types.DELETEPROFILE_SUCCESS:
          return {
            ...state,
            deleteprofilesuccess: action.payload,
            deleteprofileloading: false,
            error: null
          };
        case types.SENDMAIL_LOADING:
          return { ...state, sendmailloading: true, error: null };
          case types.SENDMAIL_SUCCESS:
            return { ...state, sendmailsuccess: true, sendmailloading: false, error: null };
      case types.CLEARLEAGUE:
        return { 
          ...state,
          getleaguessuccessdata: null,
          getsingleleaguessuccessdata: null
        };
      case types.CREATE_LEAGUE_LOADING:
        return {...state, createleagueloading: true};
      case types.CREATE_LEAGUE_SUCCESS:
        return {
          ...state,
          createleagueloading: false,
          createleaguesuccess: action.payload,
        }
      case types.JOIN_LEAGUE_LOADING:
          return {...state, joinleagueloading: true};
      case types.JOIN_LEAGUE_SUCCESS:
          return {
            ...state,
            joinleagueloading: false,
            joinleaguesuccess: true,
          }
          case types.DELETE_LEAGUE_LOADING:
            return {...state, deleteleagueloading: true};
        case types.DELETE_LEAGUE_SUCCESS:
            return {
              ...state,
              deleteleagueloading: false,
              deleteleaguesuccess: true,
            }
            case types.DELETE_USERLEAGUE_LOADING:
              return {...state, deleteuserleagueloading: true};
          case types.DELETE_USERLEAGUE_SUCCESS:
              return {
                ...state,
                deleteuserleagueloading: false,
                deleteuserleaguesuccess: true,
              }
              case types. DELETE_PRIVATELEAGUE_LOADING:
                return {...state, deleteprivateleagueloading: true};
            case types. DELETE_PRIVATELEAGUE_SUCCESS:
                return {
                  ...state,
                  deleteprivateleagueloading: false,
                  deleteprivateleaguesuccess: true,
                }
        case types.CLEAR_SUCCESS:
          return {
            ...state,
  createleaguesuccess: false,
  getleaguessuccess: false,
  getpublicleaguessuccess: false,
  joinleaguesuccess: false,
  getsingleleaguessuccess: false,
  deleteleaguesuccess: false,
  deleteuserleaguesuccess: false,
  deleteprivateleaguesuccess: false,
  sendmailsuccess: false,
  editprofilesuccess: null,
  error: null
          }
        case types.GET_LEAGUES_LOADING:
          return {...state, getleaguesloading: true};
        case types.GET_LEAGUES_SUCCESS:
          return {
            ...state,
            getleaguesloading: false,
            getleaguessuccess: true,
            getleaguessuccessdata: action.payload.leagues,
            privateUserPositions: action.payload.positions,
          }
          case types.UPDATE_PRIVATE_LEAGUE_POSITIONS:
            return {
              ...state,
              privateUserPositions: action.payload,
            }
          case types.UPDATE_PUBLIC_LEAGUE_POSITIONS:
            return {
              ...state,
              publicUserPositions: action.payload,
            }
          case types.GET_SINGLELEAGUES_LOADING:
            return {...state, getsingleleaguesloading: true};
          case types.GET_SINGLELEAGUES_SUCCESS:
            return {
              ...state,
              getsingleleaguessuccess: true,
              getsingleleaguesloading: false,
              getsingleleaguessuccessdata: action.payload,
            }
          case types.GET_PUBLICLEAGUES_LOADING:
            return {...state, getpublicleaguesloading: true};
          case types.GET_POINTS_LOADING:
            return {
              ...state,
              getpointsloading: true
            }
          case types.GET_POINTS_SUCCESS:
            return {
              ...state,
              getpointsloading: false,
              getpointssuccess: action.payload
            }
          case types.GET_PUBLICLEAGUES_SUCCESS:
            return {
              ...state,
              getpublicleaguesloading: false,
              getpublicleaguessuccess: true,
              publicUserPositions: action.payload.positions,
              getpublicleaguessuccessdata: action.payload.leagues,
            }
      case ACTION_FAIL:
      return { ...state,deleteprivateleagueloading: false, sendmailloading: false, createleagueloading: false, getpublicleaguesloading: false,  getleaguesloading: false, joinleagueloading: false, getsingleleaguesloading: false, deleteleagueloading: false, deleteuserleagueloading: false, getpointsloading: false};
      
      
    default:
      return state;
  }
};

export default stateReducer;


