import axios from "axios";
export const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:5009";


// axios.interceptors.request.use(config => {
//   config.headers.authorization = `Bearer ${token}`;
//   return config;
// },
// error => {
//   return Promise.reject(error)
// })

const authAxios = axios.create({
  baseURL: API_URL,
});

authAxios.interceptors.request.use(function (config) {
  // console.log("Token: " + localStorage.getItem("token"));
  config.headers.Authorization =
    "Bearer " + localStorage.getItem("token");
  return config;
});

const APPLICATION_JSON = {
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
};

export { authAxios, APPLICATION_JSON };
